import i18n from 'i18next';
import {
  formatNumberWithCurrency,
  formatNumber,
} from '../../../../utils/numbers';
import { OfferForView } from '../../../../stores/EstimatorPage/Factories/offerFactory';
import { Tab } from '../../../../models/VIEW/ViewOffersTab';
import ParentLocationService from '../../../../services/ParentLocationService';

type CreateStaticCommentProps = {
  baseMsrp: number;
  creditScoreLabel: string;
  downPayment?: number;
  dueAtSigning?: number;
  inputZipCode: string;
  markup?: number;
  mileage?: number;
  payment?: number;
  rate?: number;
  selectedOffers: OfferForView[];
  selectedTab: Tab;
  terms: string;
  tradeIn: number;
};
export const createStaticComment = ({
  baseMsrp,
  rate,
  terms,
  tradeIn,
  mileage,
  payment,
  selectedTab,
  downPayment,
  dueAtSigning,
  inputZipCode,
  selectedOffers,
  creditScoreLabel,
  markup,
}: CreateStaticCommentProps) => {
  let staticComment = '';
  let paymentTitle = '';
  let offerComments = '';
  let cashLabel = '';
  if (selectedTab === 'lease') {
    paymentTitle = `${i18n.t('msrp')}: $${formatNumber(baseMsrp)}\n\n${i18n.t(
      'contact_dealer_modal.estimated_payment'
    )}\n${formatNumberWithCurrency(payment)}${i18n.t(
      'contact_dealer_modal.slash_month'
    )} | ${formatNumberWithCurrency(dueAtSigning)} ${i18n.t(
      'due_at_signing'
    )} | ${formatNumber(mileage)} ${i18n.t(
      'contact_dealer_modal.miles_year'
    )} | ${terms} ${i18n.t('months').toLowerCase()}`;
    cashLabel = `${i18n.t('cash_out_of_pocket')}`;
  } else {
    paymentTitle = `${i18n.t('msrp')}: $${formatNumber(baseMsrp)}\n\n${i18n.t(
      'contact_dealer_modal.estimated_payment'
    )}\n${formatNumberWithCurrency(payment)}${i18n.t(
      'contact_dealer_modal.slash_month'
    )} | ${formatNumber( (rate ?? 0) + (markup ?? 0))} % APR | ${formatNumberWithCurrency(
      downPayment
    )} | ${terms} ${i18n.t('months').toLowerCase()}`;
    cashLabel = `${i18n.t('cash_down')}`;
  }
  const paymentDetails = `\n\n${i18n.t('credit_score')}: ${i18n.t(
    creditScoreLabel
  )}\n\n${cashLabel}: ${formatNumberWithCurrency(downPayment)}\n\n${i18n.t(
    'trade_in_cta'
  )}: ${formatNumberWithCurrency(tradeIn)}\n\n${i18n.t(
    'zip_code'
  )}: ${inputZipCode}\n\n`;

  if (selectedOffers.length > 0) {
    offerComments = `${i18n.t('contact_dealer_modal.selected_offers')}:\n`;
    selectedOffers.forEach(selectedOffer => {
      offerComments = `${offerComments}${selectedOffer.offerLabel}\n`;
    });
  } else {
    offerComments = `${i18n.t(
      'contact_dealer_modal.selected_offers'
    )}: ${i18n.t('none')}\n`;
  }

  staticComment = `${paymentTitle}${paymentDetails}${offerComments}`;
  return staticComment;
};

type CreateSelecteTrimProps = {
  gradeTitle: string;
  titleForRequest: string;
  selectedTab: Tab;
  year: string;
  trim: string;
};
export const createSelectedTrim = (props: CreateSelecteTrimProps) => ({
  year: props.year,
  model: props.gradeTitle,
  series: props.titleForRequest,
  purchaseType: props.selectedTab,
  trim: props.trim,
});

const tagsToReplace = {
  '"': '&#34;',
  '\'': '&#39;', // prettier-ignore
  '&': '&#38;',
  '<': '&lt;',
  '>': '&gt;',
};

export const sanitizeString = (input: string) => {
  return input.replace(
    /["'&<>]/g,
    tag => tagsToReplace[tag as keyof typeof tagsToReplace] || tag
  );
};

export const buildContactDealerDisclaimerLink = (useLang: string) => {
  let href;
  const isToyota = ParentLocationService.getIsToyota();

  if (isToyota && useLang === 'es') {
    // Tcom Spanish
    href = 'https://www.toyota.com/espanol/support/privacy-rights';
  } else if (isToyota && useLang === 'en') {
    // Tcom English
    href = 'https://www.toyota.com/support/privacy-rights';
  } else {
    let batTdaContext: string | string[] = ParentLocationService.getPathname();

    if (batTdaContext && batTdaContext.length > 1) {
      // localhost will just be '/'
      batTdaContext = batTdaContext.split('/');
      // BAT Spanish
      if (batTdaContext[1] === 'es') {
        batTdaContext = `${batTdaContext[1]}/${batTdaContext[2]}`; // for example /es/socal
      } else {
        // BAT English
        batTdaContext = batTdaContext[1];
      }
    } else {
      // else default to WWW
      batTdaContext = 'home';
    }
    const hostName = ParentLocationService.getHostname();
    // all TDAs should have a /legal page
    href = `//${hostName}/${batTdaContext}/legal`;
  }
  return href;
};
