import i18n from 'i18next';

import { set, decorate, observable, computed } from 'mobx';
import {
  TabUI,
  Grades,
  GradeView,
  YearSeries,
  GradeVehicleModel,
  SelectedYearSeries,
} from '../../models/VIEW/EstimatorStore';
import ZipCodeStore from '../../stores/ZipCodeStore';
import GlobalStore from '../../stores/GlobalStore';

import handleStore from '../EstimatorPage/estimatorPageHandlers';

import { navigateToPage } from '../GlobalStore/utils';
import { createSeriesOptions, createYearSeries } from './Utils/yearSeriesUtils';

import * as PostMessageService from '../../services/PostMessageService';
import * as estimatorAnalyticsService from '../../utils/Analytics/estimatorAnalytics';

import {
  preselectOffers,
  deselectAndDisableIncompatibleOffers,
  selectOffer,
} from './Factories/offersFactory';

import {
  calculateCostsForAllModelsInGrades,
  updateValues,
} from './ServiceWrappers/Calculations/calculations';

import { OfferForView, OfferType } from './Factories/offerFactory';
import { getQueryParams } from '../../utils/history';

import { history } from '../../stores/index';
import { isEmptyObject } from '../../utils/misc';
import {
  Tab,
  BuyOfferTab,
  LeaseOfferTab,
  ProcessedOffers,
} from '../../models/VIEW/ViewOffersTab';
import {
  getOffersByTierAndTerm,
  convertTierNumber,
} from './Utils/offersFactoryUtils';
import { EFCSeriesSeriesData } from '../../models/EFC/EFCSeriesV1';
import {
  getOffersForSeriesYear,
  processOffersForSeriesYear,
} from './ServiceWrappers/offers';
import {
  getSeriesAndGrades,
  getGradeGivenCode,
} from './ServiceWrappers/gradesAndSeries';
import { findLandingModelCode } from './ServiceWrappers/Calculations/lowestPaymentCalculations';

const defaultSelectedVehicle = {
  series: '',
  year: '',
  grade: '', // code
  model: '', // code
  yearSeries: 0, // index of selected yearSeries within computed yearSeries[]
  title: '',
  carJellyImage: '',
};

const EstimatorStore = {
  yearSeries: [] as YearSeries[],
  yearSeriesOptions: [] as Array<{ label: string; value: number }>,
  selectedTab: 'buy' as Tab,
  isLoaded: false,
  selectedYearSeries: {
    series: { code: '', title: '' },
    models: [],
    year: { code: '', title: '' },
    title: '',
    isLoaded: false,
    tabManager: {
      buy: {
        selectedGrade: {},
        selectedModel: {},
        selectedVehicle: defaultSelectedVehicle,
      },
      lease: {
        selectedGrade: {},
        selectedModel: {},
        selectedVehicle: defaultSelectedVehicle,
      },
    },
  } as SelectedYearSeries,
  selectedTrimID: undefined as string | undefined,

  /* Analytics */
  _dirty: false,
  printTagSent: false,
  offerChangeSent: false,
  mileageChangeSent: false,
  creditChangeSent: false,
  termChangeSent: false,
  downPaymentChangeSent: false,
  tradeInChangeSent: false,
  sendModelTag: false,
  contactButtonClick: false,
  tabChangeSent: false,

  lockVehicleTrim: false,

  // used by model select handler to see if we need to load new image
  lastCarJellyImage: '',
  isModelCodeJumped: false,

  isDirty: false,

  // handlers for messages received in post
  // message servcie from parent iframe
  // On Post Message vehicle change
  // Reloads the route with new params
  vehicleChangeCB: (value: {
    series?: string;
    year?: string;
    trim?: string;
    zip?: string;
  }) => {
    // update url state first, then piggby back fo bootstrap function...
    if (value.zip) {
      ZipCodeStore.inputZipCode = value.zip;
    }

    // update url stateso rest works...
    EstimatorStore.updateState({
      ...value,
      clearTrim: !!value.trim,
    });

    EstimatorStore.estimatorPageBootstrap();
  },

  // On Post Message zip change
  zipChangeCB: (zip: string) => {
    // Piggy back of bootstrap sequence...
    EstimatorStore.estimatorPageBootstrap(zip);
  },

  // gets series data and vehicleData for EST page
  estimatorPageBootstrap: async (zip?: string) => {
    // call get seriesData for estimator page...
    GlobalStore.isFetching = true;
    if (zip) {
      ZipCodeStore.inputZipCode = zip;
    } else {
      ZipCodeStore.setZipCodeFromQueryString();
    }
    try {
      await ZipCodeStore.getZipCode(ZipCodeStore.inputZipCode);
      await EstimatorStore.loadSeriesYearData();
    } catch (error) {
      // do nothing with error if from ZipCodeStore
      // statusbar already displayed and we've navigated
      // to zip input page
    }
  },

  setYearSeries: (filteredSeries: EFCSeriesSeriesData[]) => {
    EstimatorStore.yearSeries = createYearSeries(filteredSeries);
    EstimatorStore.yearSeriesOptions = createSeriesOptions(
      EstimatorStore.yearSeries
    );
  },

  loadSeriesYearData: async ({
    year,
    trim,
    series,
    clearTrim, // kick off process for finding trim afresh (e.g gone back to VS page and returned here to same vehicle)
  }: {
    year?: string;
    trim?: string;
    series?: string;
    clearTrim?: boolean;
  } = {}) => {
    GlobalStore.isFetching = true;

    // If we have series or year string, that means we are here from
    // a series onClick handler call, or navigated from vehicleSelect page,
    // and url query params needs to be updated so subsequent calls retrieve correct data
    // Also possible to enter if new trim has been passed down via post message service
    if ((series && year) || trim) {
      EstimatorStore.updateState({
        clearTrim,
        series,
        year,
        trim,
      });
    }
    const params = getQueryParams();
    const { trim: _trim, lock } = params;
    if (lock === 'trim') {
      EstimatorStore.lockVehicleTrim = true;
    }

    // calls getSeries and getVehicle +
    // creates grades structure for getVehicle form
    try {
      const { grades, filteredSeries } = await getSeriesAndGrades({
        region: ZipCodeStore.regionCode,
        trim: _trim as string,
      });

      EstimatorStore.setYearSeries(filteredSeries);

      // set initial selectedVehicle
      const selectedVehicle = EstimatorStore.createDefaultSelectedVehicle({
        yearSeries: EstimatorStore.yearSeries,
        grades,
      });

      // now get offers for selected vehicle and make computations...
      const yearSeries = EstimatorStore.yearSeries;
      const selectedYearSeries = yearSeries[selectedVehicle.yearSeries];
      selectedYearSeries.isLoaded = true;
      selectedYearSeries.grades = grades;

      const offersById = await getOffersForSeriesYear({
        models: selectedYearSeries.models,
        series: selectedYearSeries.series.code,
        year: selectedYearSeries.year.code,
        region: ZipCodeStore.regionCode,
        state: ZipCodeStore.stateCode,
        tdaCode: ZipCodeStore.tdaCode,
      });

      // process offers, create tab objects
      const processedOffers = processOffersForSeriesYear({
        initialOfferId: params.offer as string,
        regionCode: ZipCodeStore.regionCode,
        offersById,
      });

      // place processed models onto grades
      EstimatorStore.updateGradesModelsWithProcessedVersions(
        grades,
        processedOffers
      );

      // for each trim, calc offers which give lowest payment and preselect those
      calculateCostsForAllModelsInGrades(grades);

      const { pricingArea, regionCode, stateCode } = ZipCodeStore;

      // now call calculations endpoint, and find model landing code if not supplied
      const initialModelCodeAndTab = await findLandingModelCode({
        year: selectedVehicle.year,
        series: selectedVehicle.series,
        grades,
        tabParam: params.tab as string,
        msrpParam: params.msrp as string,
        trimParam: _trim,
        regionCode,
        pricingArea,
        state: stateCode,
        initialOfferId: params.offer as string,
        preselectedOffer: processedOffers.preselectedOffer,
      });

      EstimatorStore.selectedTab = initialModelCodeAndTab.tab as Tab;
      EstimatorStore.selectedTrimID = initialModelCodeAndTab.modelCode; // what is this being used for?!?
      selectedVehicle.model = initialModelCodeAndTab.modelCode;

      // set selected model and payOptForm data
      await EstimatorStore.setTabManager(
        initialModelCodeAndTab.modelCode,
        selectedVehicle
      );

      EstimatorStore.updateState();
      if (!EstimatorStore.isLoaded) {
        EstimatorStore.isLoaded = true;

        setTimeout(() => {
          PostMessageService.sendHeight();
        });
      }
    } catch (err) {
      if ('isAxiosError' in err) {
        GlobalStore.serviceError(err);
      } else {
        console.error('here with error', err);
      }
    }

    // we've finished.
    GlobalStore.isFetching = false;
  },

  createDefaultSelectedVehicle: ({
    yearSeries,
    grades,
  }: {
    yearSeries: YearSeries[];
    grades: Grades;
  }) => {
    const params = getQueryParams();
    const { year, series } = params;

    // 2. find index of yearSeries based of year and series
    const yearSeriesIndex = yearSeries.findIndex(
      element => element.series.code === series && element.year.code === year
    );

    // 3. set initial grade to cheapest one if no trim/model param available...
    const gradeCode = grades[0].code;

    // 4. set model to first one if no trim/model param available...
    const gradeView = getGradeGivenCode({
      grades,
      gradeCode,
    }) as GradeView;

    const selectedModel = gradeView.models[0].code;
    return {
      year: year as string,
      grade: gradeCode,
      model: selectedModel,
      series: series as string,
      yearSeries: yearSeriesIndex,
      title: yearSeries[yearSeriesIndex].title,
      carJellyImage: gradeView.models[0].carJellyImage,
    };
  },

  setTabManager: async (modelCode: string, selectedVehicle: any) => {
    // add tabManager to seriesYearItem from EstimatorStore.seriesYearItem
    set(EstimatorStore.yearSeries[selectedVehicle.yearSeries], {
      tabManager: {
        buy: {
          selectedGrade: {},
          selectedModel: {},
          selectedVehicle, // @TODO depreciate selected vehicle
        },
        lease: {
          selectedGrade: {},
          selectedModel: {},
          selectedVehicle,
        },
      },
    });

    // now assign to selectedYearSeries
    EstimatorStore.selectedYearSeries = EstimatorStore.yearSeries[
      selectedVehicle.yearSeries
    ] as SelectedYearSeries;

    // now get grades
    const grades = EstimatorStore.yearSeries[selectedVehicle.yearSeries]
      ?.grades as Grades;
    const gradeIndex = EstimatorStore.getGradeIndexByModelCode(
      grades,
      modelCode
    );
    const selectedGrade = grades[gradeIndex];

    // finaly set up selectedGrade and selectedModel on tabManager created above.
    // @TODO double check next two params, which should come from function args and below in call stack
    await EstimatorStore.selectGrade({
      grade: selectedGrade,
      byuser: true,
      _caller: 'grade',
      modelCode,
    });
  },

  get selectedVehicle() {
    return (
      EstimatorStore.selectedYearSeries?.tabManager?.[
        EstimatorStore.selectedTab
      ]?.selectedVehicle ?? {
        series: '',
        year: '',
        grade: '', // code
        model: '', // code
        yearSeries: null, // index of selected yearSeries within computed yearSeries[]
        title: '',
        carJellyImage: '',
      }
    );
  },

  get payOptForm() {
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ].selectedModel as GradeVehicleModel;
    const selectedGrade = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ].selectedGrade as GradeView;
    return {
      ...selectedModel.peOffers[EstimatorStore.selectedTab],
      isLeaseIneligible: selectedModel.peOffers['lease'].isLeaseIneligible,
      title: selectedModel.title,
      carJellyImage: selectedModel.carJellyImage,
      baseMsrp: selectedModel.baseMsrp,
      models: selectedGrade?.models ?? [],
      gradeCode: selectedGrade.code,
      modelCode: selectedModel.code,
      selectedGradeTitle: selectedGrade.title,
      grades: EstimatorStore.selectedYearSeries.grades,
    };
  },

  getGradeIndexByModelCode: (grades: Grades, modelCode?: string) => {
    if (!modelCode) {
      return 0;
    }
    for (let i = 0; i < grades.length; i++) {
      const gradeItem = grades[i];
      for (const model of gradeItem.models) {
        if (model.code === modelCode) {
          return i;
        }
      }
    }
    return 0;
  },

  // We want to unselect offer when user manually change terms (e.g. down payment, trade-in, terms...)
  checkLeaseOfferAgainstForm: (peOffer: BuyOfferTab | LeaseOfferTab) => {
    peOffer.offers.forEach(offer => {
      if (
        offer.selected &&
        offer.offerType === OfferType.LEASE &&
        (peOffer.tradeIn ||
          peOffer.downPayment !== offer.downPayment ||
          peOffer.mileage !== offer.mileage)
      ) {
        offer.selected = false;
        const tfsSubCashOffer = peOffer.offers.find(
          tempOffer =>
            tempOffer.offerType === OfferType.TFS_LEASE_SUB_CASH &&
            tempOffer.amount === offer.subCash
        );

        if (tfsSubCashOffer) {
          tfsSubCashOffer.selected = false;
        }

        deselectAndDisableIncompatibleOffers(peOffer.offers);
      }
    });
  },

  // handles recalling computeCalculations and analytics
  onFormChange: ({
    title,
    clickType,
    sendAnalytic,
    isUpdateRequired = true,
  }: {
    title: string;
    clickType: any;
    sendAnalytic: boolean;
    isUpdateRequired?: boolean;
  }) => {
    const type = EstimatorStore.selectedTab;
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[type]
      .selectedModel as GradeVehicleModel;
    const peOffer = selectedModel.peOffers[type];
    if (type === 'lease') {
      EstimatorStore.checkLeaseOfferAgainstForm(peOffer);
    }

    if (isUpdateRequired) {
      EstimatorStore.updateValues(EstimatorStore.selectedTab);
    }

    if (!sendAnalytic) {
      return;
    }

    if (title && clickType) {
      estimatorAnalyticsService.firePayOptTag({
        link_text: title,
        link_type_click: clickType,
      });
    } else {
      estimatorAnalyticsService.firePayOptTag();
    }
  },

  selectedOffersOnTierTermChange: (tab: Tab) => {
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[tab]
      .selectedModel as GradeVehicleModel;
    selectedModel.peOffers[tab].offers = getOffersByTierAndTerm({
      tier: selectedModel.peOffers[tab].creditScore.tier,
      term: selectedModel.peOffers[tab].terms,
      tierTermsOffersMap: selectedModel.peOffers[tab].tierTermsOffersMap,
    });
  },

  updateOffersOnFormChange: (tab: Tab) => {
    const selectedTab = tab || EstimatorStore.selectedTab;
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
      selectedTab
    ].selectedModel as GradeVehicleModel;
    EstimatorStore.checkToDeselectLease();
    selectedModel.peOffers[selectedTab].offers = preselectOffers({
      tab: selectedModel.peOffers[selectedTab],
      offers: selectedModel.peOffers[selectedTab].offers,
      offerType: selectedModel.peOffers[selectedTab].__offerType__,
    });
    deselectAndDisableIncompatibleOffers(
      selectedModel.peOffers[selectedTab].offers
    );
  },

  updateGradesModelsWithProcessedVersions: (
    grades: Grades,
    processedOffers: ProcessedOffers
  ) => {
    grades.forEach(grade => {
      grade.models.forEach(model => {
        processedOffers.offers.forEach(offer => {
          if (model.code === offer.model) {
            model.peOffers = {
              buy: offer.processedOffers.buy,
              lease: offer.processedOffers.lease,
            };
          }
        });
      });
    });
  },

  // BAT-5746 If toggling to Lease, deselect the Lease offer
  checkToDeselectLease: () => {
    const details = (
      EstimatorStore.selectedYearSeries.tabManager[EstimatorStore.selectedTab]
        .selectedModel as GradeVehicleModel
    ).peOffers[EstimatorStore.selectedTab];
    if (EstimatorStore.selectedTab === 'lease' && details.offers) {
      details.offers.forEach(offer => {
        if (
          offer.offerType === OfferType.LEASE &&
          offer.selected &&
          (offer.downPayment !== details.downPayment || details.tradeIn)
        ) {
          offer.selected = false;
        }
      });
    }
  },

  offerSelect: async (offer: OfferForView) => {
    offer.selected = !offer.selected;
    // First determine if lease needs to jump model codes
    if (
      offer.offerType === OfferType.LEASE &&
      offer.selected &&
      offer.modelCode !== offer.selectedModelCode
    ) {
      EstimatorStore.handleOfferSelectLeaseJump(offer);
      // Next determine if the lease must jump to the correct configured tier term
    } else {
      EstimatorStore.handleOfferSelectNotLeaseJump(offer);
    }
    if (
      (
        EstimatorStore.selectedYearSeries.tabManager[EstimatorStore.selectedTab]
          .selectedModel as GradeVehicleModel
      ).peOffers
    ) {
      estimatorAnalyticsService.fireOfferTag();
    }
  },

  handleOfferSelectLeaseJump: (offer: OfferForView) => {
    const selectedVehicle =
      EstimatorStore.selectedYearSeries.tabManager[EstimatorStore.selectedTab]
        .selectedVehicle;
    offer.selected = false; // keep this offer unselected to select the one under the different modelCode
    // set the grade
    const grades = EstimatorStore.yearSeries[
      EstimatorStore.selectedVehicle.yearSeries
    ]?.grades as Grades;
    const gradeIndex = EstimatorStore.getGradeIndexByModelCode(
      grades,
      offer.modelCode
    );
    const gradeItem = grades[gradeIndex];

    const selectedGrade = gradeItem;
    EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ].selectedGrade = selectedGrade;
    selectedVehicle.grade = gradeItem.code;
    // set the model
    const modelItem = selectedGrade.models.find(
      model => model.code === offer.modelCode
    );
    let doUpdate = false;
    if (modelItem?.peOffers) {
      // update selectedModel

      EstimatorStore.selectedYearSeries.tabManager[
        EstimatorStore.selectedTab
      ].selectedModel = modelItem;

      handleStore.onTermsChange(Number(offer.terms));

      selectedVehicle.model = modelItem.code;
      EstimatorStore.lastCarJellyImage = modelItem.carJellyImage;
      modelItem.peOffers.lease.offers.forEach(leaseOffer => {
        if (
          leaseOffer.modelCode === offer.modelCode &&
          leaseOffer.isLeaseExample === offer.isLeaseExample &&
          leaseOffer.payment === offer.payment &&
          leaseOffer.dueAtSigning === offer.dueAtSigning
        ) {
          doUpdate = !leaseOffer.selected;
          leaseOffer.selected = true;
          // WFMB-268: lease jumping on offer with non default mileage
          modelItem.peOffers.lease.mileage = leaseOffer.mileage
            ? leaseOffer.mileage
            : modelItem.peOffers.lease.mileage;
        } else {
          if (
            leaseOffer.offerType !== OfferType.TFS_LEASE_SUB_CASH ||
            leaseOffer.amount !== offer.subCash
          ) {
            leaseOffer.selected = false;
          } else {
            leaseOffer.selected = true;
          }
        }
      });

      deselectAndDisableIncompatibleOffers(
        modelItem.peOffers[EstimatorStore.selectedTab].offers
      );

      EstimatorStore.updateState();
      if (doUpdate) {
        EstimatorStore.updateValues(EstimatorStore.selectedTab);
      } else {
        // if offers doesn't exist, selectModel() will fetch detailed data from EFC and get the offers
        EstimatorStore.selectModel(modelItem);
      }
      EstimatorStore.isModelCodeJumped = true;
    }
  },

  handleOfferSelectNotLeaseJump: (offer: OfferForView) => {
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ].selectedModel as GradeVehicleModel;
    selectOffer({
      tab: selectedModel.peOffers[EstimatorStore.selectedTab],
      offer,
      offers: selectedModel.peOffers[EstimatorStore.selectedTab].offers,
      offerType:
        selectedModel.peOffers[EstimatorStore.selectedTab].__offerType__,
      preserveOfferSelection: false,
    });

    deselectAndDisableIncompatibleOffers(
      selectedModel.peOffers[EstimatorStore.selectedTab].offers
    );

    EstimatorStore.updateState();
    EstimatorStore.updateValues(EstimatorStore.selectedTab);
  },

  updateValues: async (tab: Tab) => {
    const tabManagerData = EstimatorStore.selectedYearSeries.tabManager[tab];
    const selectedModel = tabManagerData.selectedModel as GradeVehicleModel;
    const { targetPayment, dueAtSigning, rate } = await updateValues({
      type: tab,
      model: selectedModel,
      regionCode: ZipCodeStore.regionCode,
      pricingArea: ZipCodeStore.pricingArea,
      year: tabManagerData.selectedVehicle.year,
      series: tabManagerData.selectedVehicle.series,
      state: ZipCodeStore.stateCode ?? '',
    });

    const peOffer = selectedModel.peOffers[tab];
    set(peOffer, {
      payment: targetPayment,
      dueAtSigning,
      rate,
    });

    PostMessageService.sendHeight();
  },

  // if series, year and clearTrim are present,
  // then we are updating url to relfect chosen series/year,
  // and parent iframe via PostMessageService
  updateState: ({
    clearTrim,
    series,
    year,
    trim,
    zip,
  }: {
    clearTrim?: boolean;
    series?: string;
    year?: string;
    trim?: string;
    zip?: string;
  } = {}) => {
    const tabManagerData = EstimatorStore.selectedYearSeries.tabManager
      ? EstimatorStore.selectedYearSeries.tabManager[EstimatorStore.selectedTab]
      : ({} as TabUI);
    const selectedModel =
      (tabManagerData?.selectedModel as GradeVehicleModel) ?? {};
    const qParams = getQueryParams();
    const params: {
      zip: string;
      year: string;
      series: string;
      trim?: string;
      lang?: string;
      msrp?: string;
      lock?: string;
      ref?: string;
      xact?: string;
    } = {
      zip: zip || ZipCodeStore.inputZipCode,
      year: year || tabManagerData.selectedVehicle.year,
      series: series || tabManagerData.selectedVehicle.series,
      trim: trim || tabManagerData?.selectedVehicle?.model,
      lang: undefined,
      lock: undefined,
      xact: '',
    };
    if (clearTrim) {
      delete params.trim;
    }
    if (qParams.lang !== undefined) {
      params.lang = qParams.lang as unknown as string;
    }
    if (qParams.lock !== undefined) {
      params.lock = qParams.lock as unknown as string;
    }
    if (qParams.ref !== undefined) {
      params.ref = qParams.ref as unknown as string;
    }
    if (selectedModel.isCustomMsrp) {
      params.msrp = String(selectedModel.baseMsrp);
    }
    if (qParams.xact !== undefined) {
      params.xact = qParams.xact as unknown as string;
    }

    // update url...
    navigateToPage(history, 'estimator', params);

    // Send state to parent frame.
    const jsonParams = JSON.stringify(params);
    PostMessageService.stateChange(jsonParams);
  },

  selectYearSeries: async (seriesYearItem: YearSeries, byUser: boolean) => {
    // If item is not loaded, fetch data from service,
    // process into factory objects
    // figure which model code to land on (incoming trim, incoming offer, lowest payment, etc...)
    if (!seriesYearItem.isLoaded) {
      // if we are here,
      await EstimatorStore.loadSeriesYearData({
        series: seriesYearItem.series.code,
        year: seriesYearItem.year.code,
        clearTrim: true,
      });
    } else {
      // grab old peOffers before moving on...
      const peOffer = (
        EstimatorStore.selectedYearSeries.tabManager[EstimatorStore.selectedTab]
          .selectedModel as GradeVehicleModel
      ).peOffers[EstimatorStore.selectedTab];

      // update selectedYearSeries with existing seriesYearItem
      EstimatorStore.selectedYearSeries = seriesYearItem as SelectedYearSeries;
      const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
        EstimatorStore.selectedTab
      ].selectedModel as GradeVehicleModel;
      const newPeOffer = {
        ...selectedModel.peOffers[EstimatorStore.selectedTab],
      };

      // check if lease is eligible, if not then switch to buy tab
      if (
        EstimatorStore.selectedTab === 'lease' &&
        selectedModel.peOffers['lease'].isLeaseIneligible
      ) {
        EstimatorStore.selectedTab = 'buy';
        EstimatorStore.selectYearSeries(seriesYearItem, byUser);
        return;
      }

      // Maintain selected tier and terms for new peOffer
      newPeOffer.offers = getOffersByTierAndTerm({
        tier: convertTierNumber(peOffer.tier),
        term: peOffer.terms,
        tierTermsOffersMap:
          selectedModel.peOffers[EstimatorStore.selectedTab].tierTermsOffersMap,
      });

      // Main user input values for new peOffer
      selectedModel.peOffers[EstimatorStore.selectedTab].creditScore =
        peOffer.creditScore;
      selectedModel.peOffers[EstimatorStore.selectedTab].tier =
        peOffer.creditScore.id;
      selectedModel.peOffers[EstimatorStore.selectedTab].downPayment =
        peOffer.downPayment;
      selectedModel.peOffers[EstimatorStore.selectedTab].tradeIn =
        peOffer.tradeIn;

      // Load rest of values into form
      EstimatorStore.checkToDeselectLease();
      selectedModel.peOffers[EstimatorStore.selectedTab].offers =
        preselectOffers({
          tab: selectedModel.peOffers[EstimatorStore.selectedTab],
          offers: selectedModel.peOffers[EstimatorStore.selectedTab].offers,
          offerType:
            selectedModel.peOffers[EstimatorStore.selectedTab].__offerType__,
        });

      deselectAndDisableIncompatibleOffers(
        selectedModel.peOffers[EstimatorStore.selectedTab].offers
      );

      // Update the local statefullness.
      EstimatorStore.updateState();
    }
  },

  selectModel: (model: GradeVehicleModel, byuser = false) => {
    // if we don't have a selectedModel set
    const tabManagerDataBuy =
      EstimatorStore.selectedYearSeries.tabManager['buy'];
    const tabManagerDataLease =
      EstimatorStore.selectedYearSeries.tabManager['lease'];
    const tabManagerData =
      EstimatorStore.selectedYearSeries.tabManager[EstimatorStore.selectedTab];
    if (
      isEmptyObject(tabManagerDataBuy.selectedModel) ||
      isEmptyObject(tabManagerDataLease.selectedModel)
    ) {
      tabManagerDataBuy.selectedModel = tabManagerDataLease.selectedModel =
        model;
    } else {
      tabManagerData.selectedModel = model;
    }

    const selectedGrade = tabManagerData.selectedGrade as GradeView;
    const selectedModel = tabManagerData.selectedModel as GradeVehicleModel;
    if (!selectedGrade?.lastSelectedModel) {
      selectedGrade.lastSelectedModel = model;
    }
    if (byuser) {
      tabManagerData.selectedVehicle.model = model.code;
    }

    selectedModel.peOffers[EstimatorStore.selectedTab].offers = preselectOffers(
      {
        tab: selectedModel.peOffers[EstimatorStore.selectedTab],
        offers: selectedModel.peOffers[EstimatorStore.selectedTab].offers,
        offerType:
          selectedModel.peOffers[EstimatorStore.selectedTab].__offerType__,
      }
    );
    EstimatorStore.selectedOffersOnTierTermChange(EstimatorStore.selectedTab);
    deselectAndDisableIncompatibleOffers(
      selectedModel.peOffers[EstimatorStore.selectedTab].offers
    );

    // maintain current state of tier/term dropdowns
    const creditScore =
      selectedModel.peOffers[EstimatorStore.selectedTab].creditScore;
    const terms = selectedModel.peOffers[EstimatorStore.selectedTab].terms;
    selectedModel.peOffers[EstimatorStore.selectedTab].creditScore =
      creditScore;
    selectedModel.peOffers[EstimatorStore.selectedTab].terms = terms;

    // handle showing lease ineligble status bar message here,
    if (selectedModel.peOffers['lease'].isLeaseIneligible) {
      GlobalStore.showStatusBar(i18n.t('lease_ineligible'));
    }

    // check to see if we need to disable lease toggle
    if (
      EstimatorStore.selectedTab === 'lease' &&
      selectedModel.peOffers['lease'].isLeaseIneligible
    ) {
      EstimatorStore.selectedTab = 'buy';
      EstimatorStore.selectModel(model, byuser);
      return;
    }

    EstimatorStore.updateState();
  },

  selectGrade: async ({
    grade,
    byuser,
    _caller,
    modelCode,
  }: {
    grade: GradeView;
    byuser: boolean;
    _caller?: string;
    modelCode?: string;
  }) => {
    const tabManager =
      EstimatorStore.selectedYearSeries.tabManager[EstimatorStore.selectedTab];

    if (isEmptyObject(tabManager.selectedGrade)) {
      EstimatorStore.selectedYearSeries.tabManager['buy'].selectedGrade = grade;
      EstimatorStore.selectedYearSeries.tabManager['lease'].selectedGrade =
        grade;
    } else {
      tabManager.selectedGrade = grade;
    }

    // grade select from parent source
    let { trim: checkTrimID } = getQueryParams();

    if (!checkTrimID) {
      checkTrimID = modelCode as string;
    }

    if (grade.lastSelectedModel) {
      // BAT-5088
      checkTrimID = grade.lastSelectedModel.code;
    }
    // CHECK FOR PASSED IN TRIM.
    let mindex = 0; // DEFAULT, SELCT FIRST THING.
    for (let i = 0; i < grade.models.length; i++) {
      if (grade.models[i]?.code === checkTrimID) {
        mindex = i;
        break;
      }
    }

    // used found model to detect if lease is eligible, if not, run function using buy
    if (
      EstimatorStore.selectedTab === 'lease' &&
      grade.models[mindex].peOffers[EstimatorStore.selectedTab]
        .isLeaseIneligible
    ) {
      EstimatorStore.selectedTab = 'buy';
      EstimatorStore.selectGrade({ grade, byuser, _caller });
      return;
    }

    // select model
    EstimatorStore.selectModel(grade.models[mindex], byuser);
    await EstimatorStore.updateValues(EstimatorStore.selectedTab);
  },
};

decorate(EstimatorStore, {
  isLoaded: observable,
  selectedTab: observable,
  yearSeriesOptions: observable,
  yearSeries: observable,
  payOptForm: computed,
  selectedTrimID: observable,
  lastCarJellyImage: observable,
  isModelCodeJumped: observable,
  selectedYearSeries: observable, // required for computed properties
  selectedVehicle: computed,
  lockVehicleTrim: observable,
});

export default EstimatorStore;
