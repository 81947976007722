import {
  OfferForView,
  OfferType,
} from '../../stores/EstimatorPage/Factories/offerFactory';
import i18n from 'i18next';

/* Creates sub disclaimer text for amount due at signing and downpayment fields */
export const createDownpaymentDisclaimer = (offers: OfferForView[] = []) => {
  // get cash offers
  const offerArray = offers.filter(offer => offer.amount && offer.selected);

  // get string of cash offers
  let retStringDp = '';
  offerArray.forEach((offer, index) => {
    retStringDp +=
      index === offerArray.length - 1
        ? ` ${i18n.t('due_at_signing_tooltip.and')} ${offer.offerType}`
        : `${offer.offerType}, `;
  });

  // get pre-string based on if cash offers exists
  const newKey = `down_payment_tooltip${
    offerArray.length ? '.with_cash_offer' : '.no_cash_offer'
  }`;

  return `${i18n.t(newKey)}${retStringDp}`;
};

export const createDueAtSigningDisclaimer = (
  regionCode: string,
  securityDepositWaiver = false,
  selectedOffers: OfferForView[] = []
) => {
  // get selected lease offer
  const selectedLeaseOffer = selectedOffers.filter(
    offer => offer.offerType === OfferType.LEASE
  );

  // if lease offer exists, check if acfee is part of due at signing or is 0
  let acFeeIncluded = true;
  if (selectedLeaseOffer.length) {
    // tslint:disable-next-line
    if (
      regionCode === '600' ||
      // !selectedLeaseOffer[0].acquisitionFee ||
      (selectedLeaseOffer.length &&
        selectedLeaseOffer[0].dueAtSigning ===
          (selectedLeaseOffer[0].payment || 0) +
            (selectedLeaseOffer[0].downPayment || 0) +
            (selectedLeaseOffer[0].subventionCash || 0))
    ) {
      acFeeIncluded = false;
    }
  }

  const isComplimentaryFirstMonthOffer = selectedOffers.some(
    (offer: OfferForView) =>
      offer.offerType === OfferType.COMPLIMENTARY_FIRST_PAYMENT
  );

  const useLang = i18n.language;

  // english uses cased version of string, spanish must be foreced to lower.
  const cashOutOfPocketString =
    useLang === 'es'
      ? i18n.t('cash_out_of_pocket').toLowerCase()
      : i18n.t('cash_out_of_pocket');

  // Includes First Month’s Payment, Cash Out of Pocket, and [Acquisition Fee]
  const includesArr = [
    isComplimentaryFirstMonthOffer
      ? i18n.t('due_at_signing_tooltip.complementary_first_month_payment')
      : i18n.t('due_at_signing_tooltip.first_monthly_payment'),
    cashOutOfPocketString,
  ];
  // Excludes tax, title, licensing, fees, applicable rebates or cash offers, and [security deposit].
  const excludesArr = [
    i18n.t('due_at_signing_tooltip.tax_title_licensing'),
    i18n.t('due_at_signing_tooltip.fees'),
    i18n.t('due_at_signing_tooltip.applicable_rebates_cash_offers'),
  ];
  if (acFeeIncluded) {
    includesArr.push(i18n.t('due_at_signing_tooltip.acquisition_fee'));
  } else {
    excludesArr.push(i18n.t('due_at_signing_tooltip.acquisition_fee'));
  }
  if (securityDepositWaiver) {
    excludesArr.push(i18n.t('due_at_signing_tooltip.security_deposit'));
  } else {
    includesArr.push(i18n.t('due_at_signing_tooltip.security_deposit'));
  }
  return `${i18n.t('due_at_signing_tooltip.includes')}${arrayToSentence(
    includesArr
  )}. ${i18n.t('due_at_signing_tooltip.excludes')}${arrayToSentence(
    excludesArr
  )}.`;
};

export const arrayToSentence = (arr: string[]) => {
  const last = arr.pop();
  if (arr.length) {
    return `${arr.join(', ')}${arr.length > 1 ? ',' : ''} ${i18n.t(
      'due_at_signing_tooltip.and'
    )} ${last}`;
  } else {
    return last;
  }
};
