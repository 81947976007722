import React from 'react';

import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { useAutoFormatValueOnBlur } from '../../../../../../hooks/useAutoFormatOnBlur';
import { formatNumberWithCurrency } from '../../../../../../utils/numbers';

import styles from './Input.module.scss';

type Props = {
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  format?: boolean;
};
const Input: React.FC<Props> = ({
  value,
  onChange,
  format = true,
  disabled = false,
}) => {
  const [onFocus, onBlur, formatedValue] = useAutoFormatValueOnBlur(
    format,
    formatNumberWithCurrency,
    value
  );

  return (
    <input
      className={cx(styles.input, { [styles.disabled]: disabled })}
      value={formatedValue as string}
      onChange={onChange}
      onFocus={onFocus as React.EventHandler<React.FocusEvent>}
      onBlur={onBlur as React.EventHandler<React.FocusEvent>}
      type="text"
      disabled={disabled}
      maxLength={5}
    />
  );
};

export default observer(Input);
