import React from 'react';
import { useStore } from '../../../../../../stores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './PaymentDetails.module.scss';

import Disclaimer from '../../../Disclaimer';
import Cell from '../../../Cells';
import {
  formatNumber,
  formatNumberWithCurrency,
} from '../../../../../../utils/numbers';
import {
  createDownpaymentDisclaimer,
  createDueAtSigningDisclaimer,
} from '../../../../../../modules/Estimator/utils';
import { Tab } from '../../../../../../models/VIEW/ViewOffersTab';

type Props = {
  selectedTab: Tab;
};

const PaymentDetails: React.FC<Props> = ({ selectedTab }) => {
  const { t } = useTranslation();
  const {
    estimatorStore,
    zipCodeStore: { regionCode },
  } = useStore();
  const { payOptForm } = estimatorStore;

  const isBuyTab = selectedTab === 'buy';
  const subtitle = isBuyTab ? t('buy') : t('lease');
  const thirdPaymentCell = isBuyTab ? t('estimated_apr') : t('annual_mileage');
  const thirdPaymentCellDisclaimer = t('estimated_apr_tooltip');
  const fourthPaymentCell = isBuyTab
    ? t('down_payment')
    : t('amount_due_at_signing');
  const {
    terms,
    mileage,
    rate,
    downPaymentSum,
    dueAtSigning,
    payment,
    offers,
    selectedOffers,
    securityDepositWaiver,
  } = payOptForm;

  const formatedPayment = typeof payment === 'number' ? String(payment) : '-';

  const formatedFourthPaymentValue = isBuyTab
    ? formatNumberWithCurrency(downPaymentSum)
    : formatNumberWithCurrency(dueAtSigning);

  const fourthCellDisclaimer = isBuyTab
    ? createDownpaymentDisclaimer(offers)
    : createDueAtSigningDisclaimer(
        regionCode,
        securityDepositWaiver,
        selectedOffers
      );

  return (
    <div>
      <label className={styles.subtitle}>{subtitle}</label>
      <div className={styles.breakDownWrapper}>
        <Cell
          className={styles.cell}
          valueClassName={styles.cellValue}
          value={formatedPayment}
          addCurrency
          animated={!isNaN(Number(formatedPayment))}
          unit={t('monthly_payment')}
        />
        <Cell
          className={styles.cell}
          valueClassName={styles.cellValue}
          value={terms}
          unit={t('months')}
        />
        <Cell
          className={styles.cell}
          valueClassName={styles.cellValue}
          value={isBuyTab ? formatNumber(rate) : formatNumber(mileage)}
          unit={thirdPaymentCell}
        >
          {isBuyTab && (
            <Disclaimer
              text={thirdPaymentCellDisclaimer}
              className={styles.disclaimer}
            />
          )}
        </Cell>
        <Cell
          value={formatedFourthPaymentValue}
          unit={fourthPaymentCell}
          className={styles.cell}
          valueClassName={styles.cellValue}
        >
          <Disclaimer
            text={fourthCellDisclaimer}
            className={styles.disclaimer}
          />
        </Cell>
      </div>
    </div>
  );
};

export default observer(PaymentDetails);
