import React from 'react';
import cx from 'classnames';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../../stores';
import handlerStore from '../../../../stores/EstimatorPage/estimatorPageHandlers';

import styles from './ChangeVehicleForm.module.scss';
import Dropdown from './components/DropdownMenu';

import {
  Grades,
  GradeVehicleModel,
} from '../../../../models/VIEW/EstimatorStore';

import { formatNumber } from '../../../../utils/numbers';
import InfoIconPopup from '../../../../components/InfoIconPopup';
import { onEnterPress } from 'utils/onEnterPress';

const createGradeOptions = (grades?: Grades) =>
  grades?.map(grade => ({ label: grade.title, value: grade.code })) ?? [];

const createModelOptions = (models: GradeVehicleModel[]) =>
  models.map(model => ({ label: model.title, value: model.code }));

const ChangeVehicleForm: React.FC = () => {
  const { t } = useTranslation();
  const {
    estimatorStore: {
      payOptForm,
      yearSeriesOptions,
      selectedVehicle,
      isModelCodeJumped,
    },
    modalStore: { openDisclaimerModal },
  } = useStore();
  const grades = payOptForm.grades;

  const seriesOptions = yearSeriesOptions;
  const gradeOptions = createGradeOptions(grades);
  const modelOptions = payOptForm?.models
    ? createModelOptions(payOptForm.models)
    : [];
  const MSRP = payOptForm.baseMsrp;

  return (
    <section className={styles.vehicleSelect}>
      <Dropdown
        options={seriesOptions}
        value={selectedVehicle.yearSeries}
        onClick={handlerStore.onSelectedYearSeriesChange}
        ariaLabel={t('change_vehicle_form.vehicle_series')}
      />
      <Dropdown
        options={gradeOptions}
        value={payOptForm.gradeCode}
        onClick={handlerStore.onSelectedGradeChange}
        valueClassName={cx({ [styles.isModelCodeJumped]: isModelCodeJumped })}
        ariaLabel={t('change_vehicle_form.vehicle_grade')}
      />
      <Dropdown
        options={modelOptions}
        value={payOptForm.modelCode}
        onClick={handlerStore.onSelectedModelChange}
        ariaLabel={t('change_vehicle_form.vehicle_description')}
      />
      <div className={styles.msrp}>
        ${formatNumber(MSRP)} {t('msrp')}
        <button
          className={styles.sup}
          onClick={() => openDisclaimerModal('7')}
          onKeyPress={e => onEnterPress(e, () => openDisclaimerModal('7'))}
        >
          7
        </button>
        <InfoIconPopup
          content={t('estimated_price_disclaimer')}
          iconClassName={styles.infoIcon}
        />
      </div>
    </section>
  );
};

export default observer(ChangeVehicleForm);
