import React from 'react';
import { useTranslation } from 'react-i18next';

import { OfferForView } from '../../../../../../stores/EstimatorPage/Factories/offerFactory';
import styles from './titles.module.scss';

type OfferDetailsProps = {
  offer: OfferForView;
};

export const AprTitle: React.FC<OfferDetailsProps> = ({ offer }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.offerInfo}>
      <span>
        {offer.offerLabel
          .split('|')[0]
          .substring(0, offer.offerLabel.indexOf('|'))
          .replace(/\s/g, '')}
        <div>APR</div>
      </span>
      <span>
        {offer.offerLabel.split('|')[1].substring(0, 3)}
        <label>{t('mos')}</label>
      </span>
    </div>
  );
};

export const LeaseTitle: React.FC<OfferDetailsProps> = ({ offer }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.offerInfo}>
      <span>
        {offer.offerLabel
          .split('|')[0]
          .substring(0, offer.offerLabel.indexOf('|'))}
        <label>{t('per_month')}</label>
      </span>
      <span>
        {offer.offerLabel.split('|')[1].substring(0, 3)}
        <label>{t('mos')}</label>
      </span>
      <span>
        {`$${offer.dueAtSigning}`}
        <label>{t('due_at_signing')}</label>
      </span>
    </div>
  );
};

export const CustomerCashTitle: React.FC<OfferDetailsProps> = ({ offer }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.offerInfo}>
      <span>
        {`$${offer.amount}`}
        <label>{t('cash_back')}</label>
      </span>
    </div>
  );
};

export const DefaultTitle: React.FC<OfferDetailsProps> = ({ offer }) => (
  <div className={styles.offerInfo}>
    <span className={styles.cashBack}>
      {`$${offer.amount}`}
      <div>Cash label</div>
    </span>
  </div>
);
