import {
  BuyOfferTab,
  LeaseOfferTab,
} from '../../../../models/VIEW/ViewOffersTab';
import { OfferForView, OfferType } from '../../Factories/offerFactory';
import {
  getNonAdOffer,
  createTierTermKey,
} from '../../Utils/offersFactoryUtils';
import { Subvention } from '../../Utils/subventionUtils';

export const initialiseOffersForCalculation = (
  tab: BuyOfferTab | LeaseOfferTab
) => {
  const miscOffers: OfferForView[] = [];
  const unselectedRateOffers: OfferForView[] = [];
  let selectedRateOffer: OfferForView | undefined;
  let customerCashOffer: OfferForView | undefined;

  tab.offers.forEach(offer => {
    const {
      selected,
      isMisc,
      offerType,
      isLowestAdvertisedLease,
      isCurrentModelCode,
      isRateOffer,
    } = offer;

    if (selected && isMisc) {
      miscOffers.push(offer);
    }
    if (selected && offerType === OfferType.CUSTOMER_CASH) {
      customerCashOffer = offer;
    }
    if (
      selected &&
      (isCurrentModelCode || !isLowestAdvertisedLease) &&
      isRateOffer
    ) {
      selectedRateOffer = offer;
    }
    if (
      !selected &&
      !isLowestAdvertisedLease &&
      isRateOffer &&
      isCurrentModelCode
    ) {
      unselectedRateOffers.push(offer);
    }
  });

  return {
    miscOffers,
    customerCashOffer,
    selectedRateOffer,
    unselectedRateOffers,
  };
};

export const getNonMatchingModelCodeOffer = (
  tab: BuyOfferTab | LeaseOfferTab,
  tierTermKey: string,
  miscOffers: OfferForView[]
) => {
  let nonMatchingModelCodeOffer =
    tab.__tab__ === 'lease'
      ? tab.nonMatchingModelCodeOffer[tierTermKey]
      : undefined;

  nonMatchingModelCodeOffer =
    nonMatchingModelCodeOffer &&
    (!miscOffers.length ||
      miscOffers[0].isSubvented === nonMatchingModelCodeOffer.isSubvented)
      ? nonMatchingModelCodeOffer
      : undefined;
  return nonMatchingModelCodeOffer;
};

export const getNonAdOfferForOffersCalculation = (
  tab: BuyOfferTab | LeaseOfferTab,
  tierTermKey: string,
  miscOffers: OfferForView[]
) => {
  let nonAdOffer: OfferForView | undefined = getNonAdOffer(
    tab.tierTermsNonAdMap,
    tierTermKey
  );

  nonAdOffer =
    nonAdOffer &&
    (!miscOffers.length ||
      miscOffers[0].isSubvented === nonAdOffer.isSubvented ||
      miscOffers[0].isSubvented === Subvention.BOTH)
      ? nonAdOffer
      : undefined;

  return nonAdOffer;
};

// if used for selection of offers with lowest rate,
export const getSelectedOffersForCalculation = (
  tab: BuyOfferTab | LeaseOfferTab
) => {
  const {
    miscOffers,
    customerCashOffer,
    selectedRateOffer,
    unselectedRateOffers,
  } = initialiseOffersForCalculation(tab);

  // Handle Non Advertised
  // Check if subvented contracts are compatible with misc offers
  const tierTermKey = createTierTermKey(tab.creditScore?.tier, tab.terms);
  const nonMatchingModelCodeOffer = getNonMatchingModelCodeOffer(
    tab,
    tierTermKey,
    miscOffers
  );

  const nonAdOffer = getNonAdOfferForOffersCalculation(
    tab,
    tierTermKey,
    miscOffers
  );

  // Handle Additional Cash
  // Check if additional Cash also lives in the miscOffers array to prevent loading offer two times
  if (
    selectedRateOffer !== undefined &&
    selectedRateOffer.additionalCash &&
    !miscOffers.filter(
      offer => offer.id === selectedRateOffer?.additionalCash?.id
    ).length
  ) {
    miscOffers.push(selectedRateOffer?.additionalCash);
  }

  // Handle Bonus Cash with similar logic as Additional Cash
  if (
    selectedRateOffer &&
    selectedRateOffer.bonusCash &&
    !miscOffers.filter(
      offer => offer.amount === selectedRateOffer?.bonusCash?.amount
    ).length
  ) {
    miscOffers.push(selectedRateOffer.bonusCash);
  }

  const rateOffer = getRateOffersByPriority({
    customerCashOffer,
    selectedRateOffer,
    unselectedRateOffers,
    nonMatchingModelCodeOffer,
    nonAdOffer,
    tab,
  });

  // Return valid objects
  return [...rateOffer, customerCashOffer, ...miscOffers].filter(
    offer => offer !== undefined
  ) as OfferForView[];
};

// WFMB-268
// Priority selection for choosing rateOffer(s) to send
// 1. send seletected rate offer
// 2. pick rate offer from unselected rate offers:
//  (1) all rate offers if there are no rate offers with matching mileage,
//  (2) or rate offer with matching mileage
// 3 + 4. Consider nonMatchingModelCode and non advertised offers
const getRateOffersByPriority = ({
  customerCashOffer,
  selectedRateOffer,
  unselectedRateOffers,
  nonMatchingModelCodeOffer,
  nonAdOffer,
  tab,
}: {
  customerCashOffer?: OfferForView;
  unselectedRateOffers: OfferForView[];
  selectedRateOffer?: OfferForView;
  nonMatchingModelCodeOffer?: OfferForView;
  nonAdOffer?: OfferForView;
  tab: BuyOfferTab | LeaseOfferTab;
}) => {
  // all rate offer categories have to be filtered by same predicate
  const predicateFunction = customerCashOffer ? isSubvented : isNotUndefined;

  // send through selectedOffer if exists
  const filteredRateOffer = [selectedRateOffer].filter(predicateFunction);
  if (filteredRateOffer.length) {
    return filteredRateOffer;
  }

  // send through unselectedRateOffer(s) if exist
  const filteredUnselectedRateOffers = unselectedRateOffers.filter(
    offer => predicateFunction(offer) && !offer.disabled
  );
  if (filteredUnselectedRateOffers.length) {
    const matchingMileageNonSelectedRateOffer =
      filteredUnselectedRateOffers.filter(
        (offer: OfferForView) => offer.mileage === tab.mileage
      );

    // if we have a rate offer with matching mileage, send it
    if (matchingMileageNonSelectedRateOffer.length > 0) {
      return [matchingMileageNonSelectedRateOffer[0]];
    }

    // else send all unselectedRateOffers with non matching mileages
    return filteredUnselectedRateOffers;
  }

  // otherwise pick from nonMatchingModelCodeOffer or nonAdOffer
  return [
    [nonMatchingModelCodeOffer, nonAdOffer].filter(predicateFunction)[0],
  ].flat();
};

const isSubvented = (offer?: OfferForView) => offer && !offer.isSubvented;
const isNotUndefined = (offer?: OfferForView) => offer !== undefined;
