import React from 'react';
import cx from 'classnames';

import { Popup } from 'semantic-ui-react';

import styles from './infoIconPopup.module.scss';
import './popup.css';

const InfoIconPopup: React.FC<{ content: string; iconClassName?: string }> = ({
  content,
  iconClassName,
}) => (
  <Popup
    className="popupOverride"
    trigger={<i className={cx(styles.iconInfo, iconClassName)} />}
    position="top center"
    content={content}
    size="tiny"
    inverted
  />
);

export default InfoIconPopup;
