import React, { useState } from 'react';
import cx from 'classnames';
import sanitizeHtml from 'sanitize-html';

import styles from './DropdownMenu.module.scss';

import useOutsideAlerter from '../../../../../../hooks/useOutsideAlerter';
import { useStore } from '../../../../../../stores';
import { onEnterPress } from 'utils/onEnterPress';

type Props = {
  value: string | number;
  options: Array<{ label: string; value: any }>;
  onClick: (e: any) => void;
  valueClassName?: string;
  ariaLabel: string;
};

const findMatchingOption = (options: Props['options'], value: Props['value']) =>
  options.find(option => option.value === value);

const sanitize = (val?: string) =>
  sanitizeHtml(val || '', {
    allowedTags: ['b', 'i', 'em', 'strong'],
  });

const DropdownMenu: React.FC<Props> = ({
  value,
  options,
  onClick,
  valueClassName,
  ariaLabel,
}) => {
  const [isVisible, setShowDropdown] = useState(false);
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setShowDropdown(false);
  });
  const {
    estimatorStore: { lockVehicleTrim },
  } = useStore();
  const buttonOnClick = () => {
    setShowDropdown(!isVisible);
  };

  const dropDownOptionCLick = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    ivalue: any
  ) => {
    event.preventDefault();
    setShowDropdown(false);
    onClick(ivalue);
  };

  const valueToDisplay = sanitize(findMatchingOption(options, value)?.label);

  return (
    <div className={styles.dropdownBody} ref={wrapperRef}>
      <button
        onClick={buttonOnClick}
        className={cx(styles.btnDropdown, {
          [styles.lockVehicleTrim]: lockVehicleTrim,
        })}
        aria-expanded={isVisible}
        disabled={lockVehicleTrim}
        aria-label={ariaLabel}
        aria-haspopup={!lockVehicleTrim}
      >
        <span className={styles.caretRight}>
          <span className={styles.caret} />
        </span>
        <span
          className={cx(styles.value, valueClassName)}
          dangerouslySetInnerHTML={{ __html: valueToDisplay }}
        />
      </button>
      {isVisible && (
        <menu className={styles.dropdownList}>
          {options.map(({ label, value: ivalue }) => (
            <li key={label} className={styles.option}>
              <button
                className={cx(styles.link, {
                  [styles.selectedLink]: ivalue === value,
                })}
                dangerouslySetInnerHTML={{ __html: sanitize(label) }}
                value={value}
                onClick={event => {
                  dropDownOptionCLick(event, ivalue);
                }}
                onKeyDown={e =>
                  onEnterPress(e, () => dropDownOptionCLick(e, ivalue))
                }
              />
            </li>
          ))}
        </menu>
      )}
    </div>
  );
};

export default DropdownMenu;
