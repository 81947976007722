import React from 'react';

import { Bullet } from '../../../../../../models/PEMS/PEMMarketingData';
import styles from './marketingDetails.module.scss';

type MarketingDetailsProps = {
  marketingData: {
    bullets: Bullet[];
    disclaimer: string[];
    description: string;
    offerImage: string;
    offerImageAlt: string;
  };
};

const MarketingDetails: React.FC<MarketingDetailsProps> = ({
  marketingData,
}) => {
  return (
    <ul className={styles.offerDetailsModalList}>
      {marketingData.bullets.map(
        bullet => !bullet.link && <li key={bullet.text}>{bullet.text}</li>
      )}
      {marketingData.bullets.map(bullet => bullet.link).length !== 0 && (
        <div className={styles.divider}></div>
      )}
      {marketingData.bullets.map(
        bullet =>
          bullet.link && (
            <li key={bullet.text} className={styles.linkWrapper}>
              <a href={bullet.link}>
                <div className={styles.link}>
                  <div className={styles.linkText}>{bullet.text}</div>
                  <div className={styles.carret}>
                    <span className={styles.carret} />
                  </div>
                </div>
              </a>
            </li>
          )
      )}
    </ul>
  );
};

export default MarketingDetails;
