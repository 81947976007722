import i18n from 'i18next';
import store from '../../stores/EstimatorPage';
import { GradeVehicleModel, GradeView } from '../../models/VIEW/EstimatorStore';
import * as AnalyticsService from './index';

import BrandService from '../../services/BrandService';
import { OfferForView } from '../../stores/EstimatorPage/Factories/offerFactory';
import { Tab } from '../../models/VIEW/ViewOffersTab';
import AnalyticsConstants from '../../constants/Analytics';

// Contact Dealer Button Click
export const fireContactDealerLinkClicked = (
  offers: OfferForView[],
  isMainPageCTA = true
) => {
  const selectedOffers = offers.filter(offer => offer.selected);
  // BAT-5109.FIRE PE COMPLETE
  let block = extendWithVehicleBlock({
    link_action: 'AA-link',
    link_text: 'contact dealer',
  });
  block = extendWithPEBlock(block);
  if (isMainPageCTA) {
    block = extendWithGlobalBlock(block);
  } else {
    block.content_section = AnalyticsService.PAGE_TITLE_OFFER_MODAL;
  }
  block = AnalyticsService.createOffersBlock(
    selectedOffers,
    block,
    BrandService.getBrand()
  );
  block = AnalyticsService.getToolBlock(block);
  AnalyticsService.fireKMPEComplete(block);
};

export const fireSeeOfferDetailsLinkClicked = (offer: OfferForView) => {
  let block = extendWithVehicleBlock({
    link_action: 'AA-link',
    link_text: 'See Offer Details',
  });
  block = extendWithPEBlock(block);
  block = extendWithGlobalBlock(block);
  block = AnalyticsService.getToolBlock(block);
  block = AnalyticsService.getOfferBlock(block, offer, BrandService.getBrand());
  AnalyticsService.fireAALink(block);
};

export const fireKMPEShownTagBase = () => {
  let block = extendWithVehicleBlock({});
  block = extendWithPEBlock(block); // BAT-4751
  block = extendWithGlobalBlock(block);
  block = AnalyticsService.getToolBlock(block);
  const selectedModel = store.selectedYearSeries.tabManager[store.selectedTab]
    .selectedModel as GradeVehicleModel;

  const financeObj = selectedModel.peOffers[store.selectedTab];
  const selectedOffers = financeObj.offers.filter(offer => offer.selected);
  if (selectedOffers.length) {
    block = AnalyticsService.createOffersBlock(
      selectedOffers,
      block,
      BrandService.getBrand()
    );
  }
  return block;
};

// Tagging functions
export const fireKMPEShownTag = () => {
  const block = fireKMPEShownTagBase();
  AnalyticsService.fireKMPEShown(block);
};

// OATM-1533
export const fireAAComponentViewTag = () => {
  const block = fireKMPEShownTagBase();
  block.metrics = AnalyticsConstants.TAGS.KMPESHOWN;
  AnalyticsService.fireAACcomponentView(block);
};

const fireKMPEStartTag = (block: any) => {
  // REFACTORED TO  COMBINE THE PE START AND FIRST AA-LINK INTO ONE.
  AnalyticsService.fireKMPEStart(block);
};

export const fireVehSelTag = (params: any) => {
  let block = AnalyticsService.getToolBlock({
    link_action: 'AA-link',
  });
  block = extendWithGlobalBlock(block);
  extendParams(block, params);
  AnalyticsService.fireAALink(block);
};

export const firePayOptTag = (params?: any) => {
  let block = extendWithVehicleBlock({
    link_action: 'AA-link',
  });
  const financeObj = (
    store.selectedYearSeries.tabManager[store.selectedTab]
      .selectedModel as GradeVehicleModel
  ).peOffers[store.selectedTab];
  const selectedOffers = financeObj.offers.filter(offer => offer.selected);
  block = AnalyticsService.createOffersBlock(
    selectedOffers,
    block,
    BrandService.getBrand()
  );
  block = extendWithPEBlock(block);
  block = extendWithGlobalBlock(block);
  block = AnalyticsService.getToolBlock(block);

  if (store.contactButtonClick) {
    block['link_text'] = 'Contact Dealer'; // BAT-4585
  }

  extendParams(block, params);

  // INGORE CONTACT DEALER MODAL OPEN CLICKS.
  if (!store._dirty && !store.contactButtonClick) {
    // BAT-4585 SEND THE COMBINDED AA-LINK AND PE START
    fireKMPEStartTag(block);
    store._dirty = true;
  } else {
    // BAT-4585 NOW JUST SEND AA-LINK.
    // AnalyticsService.fireAALink(block);
    // BAT-4574 TRIGGER PE COMPLETE only contactButtonClick
    if (!store.contactButtonClick) {
      // BAT-4642
      AnalyticsService.fireAALink(block);
    }
  }
  store.contactButtonClick = false; // BAT-4585
};

export const fireOfferTag = () => {
  const financeObj = (
    store.selectedYearSeries.tabManager[store.selectedTab]
      .selectedModel as GradeVehicleModel
  ).peOffers[store.selectedTab];
  const selectedOffers = financeObj.offers.filter(offer => offer.selected);

  let block = extendWithVehicleBlock({
    link_action: 'AA-link',
    link_text: 'Offer', // BAT-4585
    link_type_click: 'Checkbox',
  });
  block = AnalyticsService.getToolBlock(block);
  block = extendWithGlobalBlock(block);
  block = AnalyticsService.createOffersBlock(
    selectedOffers,
    block,
    BrandService.getBrand()
  );
  if (!store.offerChangeSent) {
    if (!store._dirty) {
      fireKMPEStartTag(block);
      store._dirty = true;
    } else {
      AnalyticsService.fireAALink(block);
    }
    store.offerChangeSent = true;
  }
};

export const firePrintTag = () => {
  if (!store.printTagSent) {
    const financeObj = (
      store.selectedYearSeries.tabManager[store.selectedTab]
        .selectedModel as GradeVehicleModel
    ).peOffers[store.selectedTab];
    const selectedOffers = financeObj.offers.filter(offer => offer.selected);

    let block = extendWithVehicleBlock({
      link_action: 'AA-link',
      link_text: 'Print This',
    });
    block = AnalyticsService.getToolBlock(block);
    block = extendWithGlobalBlock(block);
    block = extendWithPEBlock(block);
    block = AnalyticsService.createOffersBlock(
      selectedOffers,
      block,
      BrandService.getBrand()
    );
    AnalyticsService.fireAALink(block);
    store.printTagSent = true;
  }
};

/*
 Open disclaimers modal
*/
// BAT-4748
export const fireDisclaimerTag = (id: number) => {
  let type = '';
  switch (id) {
    case 1:
      type = 'estimated credit score';
      break;
    case 2:
      type = 'down payment'; // SAME AS 8?
      break;
    case 3:
      type = 'term length';
      break;
    case 4:
      type = 'estimated apr';
      break;
    case 5:
      type = 'trade in value';
      break;
    case 6:
      type = 'annual mileage';
      break;
    case 7:
      type = 'series msrp';
      break;
    case 8:
      type = 'down payment'; // SAME AS 2?
      break;
    default:
      break;
  }
  let block = AnalyticsService.getToolBlock({
    link_action: 'AA-link',
  });
  block = extendWithGlobalBlock(block);
  block = extendWithPEBlock(block);
  block = extendWithVehicleBlock(block);
  block.link_text = `Disclaimer ${id}`;
  block.link_type_title = type;
  AnalyticsService.fireAALink(block);
};

// BAT-4748
export const fireDisclaimerPageLoadTag = () => {
  let block = extendWithVehicleBlock({});
  block = extendWithGlobalBlock(block);
  block = AnalyticsService.getToolBlock(block);
  block.content_section = 'Disclaimer modal';
  AnalyticsService.fireAAPageLoad(block);
};

function extendParams(block: any, params: any) {
  if (params) {
    for (const prop of Object.keys(params)) {
      block[prop] = params[prop];
    }
  }
}

export const extendWithGlobalBlock = (block: any) => {
  const pageTitle =
    store.selectedTab === 'lease'
      ? AnalyticsService.PAGE_TITLE_ESTIMATOR_LEASE
      : AnalyticsService.PAGE_TITLE_ESTIMATOR_FINANCE;
  return AnalyticsService.extendWithGlobalBlock(block, pageTitle);
};

export const extendWithPEBlock = (block: any) => {
  block.pe_buy_lease = store.selectedTab;
  const translatedCreditLabel = i18n.t(store.payOptForm.creditScore.label);
  block.pe_credit_score = `${translatedCreditLabel} ${store.payOptForm.creditScore.score}`; // BAT-4585 include label with credit score. ex: "excellent 720+"
  block.pe_apr_term = store.payOptForm.terms;
  block.pe_down_payment = store.payOptForm.downPayment;
  block.pe_trade_in_value = store.payOptForm.tradeIn;

  // BAT-4749
  block.pe_model_name = `${store.selectedYearSeries.year.title} ${store.selectedYearSeries.series.title}`;
  block.pe_model_type = (
    store.selectedYearSeries.tabManager[store.selectedTab]
      .selectedGrade as GradeVehicleModel
  ).title;
  block.pe_model_features = (
    store.selectedYearSeries.tabManager[store.selectedTab]
      .selectedModel as GradeVehicleModel
  ).trimmedTitle;
  if (store.payOptForm.mileage) {
    block.pe_annual_mileage = store.payOptForm.mileage;
  }

  return block;
};

export const extendWithVehicleBlock = (block: any) => {
  const selectedModel = store.selectedYearSeries.tabManager[store.selectedTab]
    .selectedModel as GradeVehicleModel;
  const selectedGrade = store.selectedYearSeries.tabManager[store.selectedTab]
    .selectedGrade as GradeView;
  block.series_number = selectedModel.code;
  block.series_msrp = selectedModel.baseMsrp;
  block.series_brand = BrandService.getBrand();
  block.series_grade = selectedGrade.code;
  block.series_engine = selectedModel.engine;
  block.series_transmission = selectedModel.transmission;
  block.series_category = selectedModel.category;
  block.series_code = store.selectedYearSeries.series.code;
  block.series_year = store.selectedYearSeries.year.code;
  return block;
};

/* Offer Details Modal*/
export const buildAnalyticsBlocksForModal = () => {
  const peBlock = extendWithPEBlock({});
  const vehicleBlock = extendWithVehicleBlock({});
  return {
    peBlock,
    vehicleBlock,
  };
};

export const fireSelectedTagChange = (tab: Tab) => {
  if (!store.tabChangeSent) {
    firePayOptTag({
      link_text: i18n.t(tab),
    });
    store.tabChangeSent = true;
  }
};

export const fireEstimatedTradeInTag = (offers: OfferForView[]) => {
  let block = AnalyticsService.getToolBlock({
    link_action: 'AA-link',
    link_text: 'Estimated Trade-In',
  });
  block = extendWithPEBlock(block);
  extendWithVehicleBlock(block);
  const selectedOffers = offers.filter(offer => offer.selected);
  block = AnalyticsService.createOffersBlock(
    selectedOffers,
    block,
    BrandService.getBrand()
  );

  AnalyticsService.fireAALink(block);
};
