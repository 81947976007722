import React from 'react';
import cx from 'classnames';
import { useAnimateNumber } from '../../../../hooks/useAnimateNumber';

import styles from './Cells.module.scss';

export const Unit: React.FC<{
  valueClassName?: string;
  className?: string;
}> = ({ children, className }) => (
  <div className={cx(styles.unit, className)}>{children}</div>
);

export const Value: React.FC<{ valueClassName?: string }> = ({
  children,
  valueClassName,
}) => <span className={cx(styles.value, valueClassName)}>{children}</span>;

type Props = {
  value: string;
  unit?: string;
  supValue?: string;
  subValue?: string;
  columnSize?: string; // e.g 2, 3, 3-5
  className?: string;
  addCurrency?: boolean;
  valueClassName?: string;
  animated?: boolean;
  subValueClassName?: string;
};

const Cell: React.FC<Props> = ({
  unit,
  value,
  supValue,
  subValue,
  children,
  columnSize,
  className,
  animated = false,
  addCurrency = false,
  valueClassName,
  subValueClassName,
}) => {
  const countUp = useAnimateNumber(Number(value));

  return (
    <div className={cx(styles.cell, styles[`xs-col-${columnSize}`], className)}>
      <span className={styles.supValue}>{supValue}</span>
      <Value valueClassName={valueClassName}>
        {addCurrency && '$'}
        {animated ? countUp : value}
      </Value>
      {subValue && (
        <span
          className={subValueClassName ? subValueClassName : styles.subValue}
        >
          {subValue}
        </span>
      )}
      {unit && <Unit className={styles.unit}>{unit}</Unit>}
      {children}
    </div>
  );
};

export default Cell;
