import React from 'react';
import { useTranslation } from 'react-i18next';

import * as estimatorAnalyticsService from '../../../../../../utils/Analytics/estimatorAnalytics';

import styles from './PrintThis.module.scss';

const PrintThis = () => {
  const { t } = useTranslation();
  const printFunction = () => {
    estimatorAnalyticsService.firePrintTag();
    window.print();
  };
  return (
    <div className={styles.print}>
      <button className={styles.button} onClick={printFunction}>
        <span className={styles.icon} />
        <span>{t('print_this')}</span>
      </button>
    </div>
  );
};

export default PrintThis;
