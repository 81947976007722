import React from 'react';
import { useTranslation } from 'react-i18next';

import { BottomSection } from '../Sections';
import styles from './BottomSectionTablet.module.scss';
import Button from '../../../../modules/ZipCode/components/Button';

import FinanceTerms from '../FinanceTerms';
import PaymentDetails from './Components/PaymentDetails';
import { useStore } from '../../../../stores';
import { Tab } from '../../../../models/VIEW/ViewOffersTab';

type Props = {
  selectedTab: Tab;
};
const BottomSectionTablet: React.FC<Props> = ({ selectedTab }) => {
  const { t } = useTranslation();
  const {
    modalStore: { openContactDealerModal },
  } = useStore();

  return (
    <BottomSection>
      <div className={styles.buttonWrapper}>
        <Button
          onClick={() => openContactDealerModal()}
          className={styles.btn}
          aria-haspopup="true"
        >
          {t('contact_dealer')}
        </Button>
      </div>
      <div className={styles.redSection}>
        <PaymentDetails selectedTab={selectedTab} />
        <FinanceTerms selectedTab={selectedTab} />
      </div>
    </BottomSection>
  );
};

export default BottomSectionTablet;
