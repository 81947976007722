import React from 'react';
import cx from 'classnames';
import styles from './Disclaimer.module.scss';

type Props = {
  text: string;
  width?: string;
  className?: string;
};
const Disclaimer: React.FC<Props> = ({ text, className, width = '100%' }) => (
  <div className={cx(styles.disclaimer, className)}>
    <div className={cx(styles.inner)} style={{ width }}>
      <span>{text}</span>
    </div>
  </div>
);

export default Disclaimer;
