import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './FinanceTerms.module.scss';
import { Tab } from '../../../../models/VIEW/ViewOffersTab';

type Props = {
  className?: string;
  selectedTab: Tab;
};

const FinanceTerms: React.FC<Props> = ({ className, selectedTab }) => {
  const { t } = useTranslation();
  const title =
    selectedTab === 'buy' ? t('finance_terms.title') : t('lease_terms.title');
  const description =
    selectedTab === 'buy'
      ? t('finance_terms.description')
      : t('lease_terms.description');

  return (
    <section className={cx(styles.terms, className)}>
      <span className={styles.title}>{title}</span>
      <p className={styles.description}>{description}</p>
    </section>
  );
};

export default FinanceTerms;
