import { decorate, observable, reaction } from 'mobx';

import EstimatorStore from '../EstimatorPage';
import { OfferForView } from '../EstimatorPage/Factories/offerFactory';
import { GradeView, GradeVehicleModel } from '../../models/VIEW/EstimatorStore';
import { Disclosures } from '../../models/VIEW/ViewOffersTab';
import * as PostMessageService from '../../services/PostMessageService';
import * as estimatorAnalyticsService from '../../utils/Analytics/estimatorAnalytics';
import { msrpDisclosure } from 'constants/Disclosures';

const ModalStore = {
  // confirm change modals
  displayConfirmGradeChangeModal: false, // on grade change when MSRP query string param present
  confirmOfferChangeForOffer: null as OfferForView | null,
  displayConfirmOfferChangeModal: false, // on lease offer click when MSRP query string param present...
  confirmGradeChangeForGrade: null as GradeView | null,

  // disclaimer modal. Move to own store
  displayDisclaimerModal: false,
  disclaimerId: '',
  disclosuresForModal: [] as Disclosures,

  // offer details modal
  displayOfferDetailsModal: false,
  offerDetailId: 0,

  // contact delear modal
  displayContactDealerModal: false,

  // kbb trade In modal
  displayKbbTradeInModal: false,

  closeDisclaimerModal: () => {
    ModalStore.displayDisclaimerModal = false;
  },

  openDisclaimerModal: (idx: string) => {
    if (window.location.href.includes('estimator')) {
      const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
        EstimatorStore.selectedTab
      ].selectedModel as GradeVehicleModel;
      ModalStore.disclosuresForModal = selectedModel.peOffers[
        EstimatorStore.selectedTab
      ].disclosures as Disclosures;

      const msrpDisclaimer = ModalStore.disclosuresForModal.find(
        disclosure => disclosure.disclosureType === 'msrpDisclosure'
      );
      if (msrpDisclaimer) {
        msrpDisclaimer.disclosureValue = msrpDisclosure;
      }
    }

    ModalStore.disclaimerId = idx;
    ModalStore.displayDisclaimerModal = true;
  },

  closeConfirmModal: () => {
    ModalStore.displayConfirmOfferChangeModal = false;
    ModalStore.displayConfirmGradeChangeModal = false;
  },

  openOfferDetailsModal: (offer: OfferForView) => {
    ModalStore.offerDetailId = offer.id;
    ModalStore.displayOfferDetailsModal = true;
    estimatorAnalyticsService.fireSeeOfferDetailsLinkClicked(offer);
  },

  closeOfferDetailsModal: () => {
    ModalStore.displayOfferDetailsModal = false;
  },

  // WFMB-385 isMainPageCTA true if main contact dealer button,
  // and false if Contact dealer button on offer details modal.
  openContactDealerModal: (isMainPageCTA = true) => {
    ModalStore.displayContactDealerModal = true;
    EstimatorStore.contactButtonClick = true;
    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ]?.selectedModel as GradeVehicleModel;
    const offers = selectedModel.peOffers[EstimatorStore.selectedTab].offers;
    estimatorAnalyticsService.fireContactDealerLinkClicked(
      offers,
      isMainPageCTA
    );
  },

  closeContactDealerModal: () => {
    ModalStore.displayContactDealerModal = false;
    setTimeout(() => {
      PostMessageService.sendHeight();
    });
  },

  openKbbTradeInModal: () => {
    ModalStore.displayKbbTradeInModal = true;
  },

  closeKbbTradeInModal: () => {
    ModalStore.displayKbbTradeInModal = false;
  },
};

decorate(ModalStore, {
  // confirm change modal
  displayConfirmOfferChangeModal: observable,
  confirmOfferChangeForOffer: observable,
  displayConfirmGradeChangeModal: observable,
  confirmGradeChangeForGrade: observable,
  // dislaimer modal
  displayDisclaimerModal: observable,
  disclaimerId: observable,
  disclosuresForModal: observable,
  // offer details modal
  displayOfferDetailsModal: observable,
  offerDetailId: observable,
  // contact dealer modal
  displayContactDealerModal: observable,
  displayKbbTradeInModal: observable,
});

// when a modal opens, excluding from offerDetails, update parent frame with new height
reaction(
  () => {
    return (
      ModalStore.displayConfirmOfferChangeModal ||
      ModalStore.displayConfirmGradeChangeModal ||
      ModalStore.displayDisclaimerModal
    );
  },
  (modalOpen: boolean) => {
    if (modalOpen) {
      PostMessageService.sendHeight();
    }
  }
);

export default ModalStore;
