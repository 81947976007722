import React from 'react';
import cx from 'classnames';

import styles from './Toggle.module.scss';

type Props = {
  onChange: (event: any) => void;
  options: Array<{ value: string; label: string }>;
  value: string;
  disabled?: boolean;
};

const Toggle: React.FC<Props> = ({
  onChange,
  options,
  value,
  disabled = false,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.toggle}>
        <div className={styles.wrapper}>
          <ul className={styles.buttons}>
            {options.map(option => {
              const thisButtonDisabled = option.value === 'lease' && disabled;
              return (
                <button
                  key={option.value}
                  className={cx(styles.button, {
                    [styles.active]: option.value === value,
                    [styles.disabled]: thisButtonDisabled,
                  })}
                  onClick={onChange}
                  value={option.value}
                  disabled={thisButtonDisabled}
                  ariab-label={`Toggle ${option.value} payment options`}
                  aria-pressed={option.value === value}
                >
                  {option.label}
                </button>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Toggle;
