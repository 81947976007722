import React from 'react';
import cx from 'classnames';

import styles from './Heading.module.scss';

import { useTranslation } from 'react-i18next';

type Props = {
  series: string;
};

const Heading: React.FC<Props> = ({ series }) => {
  const { t } = useTranslation();
  return (
    <section className={styles.heading}>
      <h1 className={styles.text}>{t('pe_title')}</h1>
      <h1 className={cx(styles.text, styles.series)}>{series}</h1>
      <p className={styles.disclaimer}>{t('pe_disclaimer')}</p>
    </section>
  );
};

export default Heading;
