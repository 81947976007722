import { observable } from 'mobx';
import { OfferForView, OfferType } from '../Factories/offerFactory';
import { getCreditScoreConstant } from '../../../constants/CreditScoreConstants';
import ZipCodeStore from 'stores/ZipCodeStore';
import {
  TierTermsOffersMap,
  BuyOfferTabOnly,
  LeaseOfferTabOnly,
  BaseOfferTab,
  BuyOfferTab,
  LeaseOfferTab,
  Tab,
  InitObj,
  TierTermsNonAdMap,
} from '../../../models/VIEW/ViewOffersTab';
import { LeaseAndAprOfferFromService } from '../../../models/PEMS/PEMOffers';
import EstimatorStore from '..';

export * from './compatibilityUtils';

const defaultBuyTabOnly = (): BuyOfferTabOnly => ({
  __name__: 'Buy',
  __tab__: 'buy',
  __offerType__: 'APR',
  terms: '60',
});

const defaultLeaseTabOnly = (): LeaseOfferTabOnly => ({
  __name__: 'Lease',
  __tab__: 'lease',
  __offerType__: 'Lease',
  terms: '36',
  rate: 0.03,
  mileage: 12000,
  nonMatchingModelCodeOffer: {},
});

const defaultTab = (): BaseOfferTab => ({
  __name__: 'Offer',
  __tab__: 'lease',
  __offerType__: 'Lease',
  terms: '72',
  creditScore: getCreditScoreConstant(
    ZipCodeStore.regionCode,
    EstimatorStore.selectedTab
  )[0],
  downPayment: 2000,
  offers: [], // current offers displayed
  tradeIn: 0,
  tier: 0,
  useCustomRate: false,
  tierTermsOffersMap: {}, // maps offers by tiers and terms
  tierTermsNonAdMap: {},
  selectedOffers: [],
  mileage: 0,
  isLeaseIneligible: false,
});

export const defaultBuyOffer = (): BuyOfferTab =>
  observable(Object.assign({}, defaultTab(), defaultBuyTabOnly()));

export const defaultLeaseOffer = (): LeaseOfferTab =>
  observable(Object.assign({}, defaultTab(), defaultLeaseTabOnly()));

export const createTierTermKey = (
  tier: string | undefined,
  term: string | number
) => `${tier} ${term}`;

const selectBaseOfferLease = (offer: OfferForView, tab: LeaseOfferTab) => {
  tab.useCustomRate = true;
  tab.terms = offer.terms as unknown as string;
  tab.rate = Number(offer.rate);
  tab.tier = offer.tier === '1+' ? 0 : Number(offer.tier) || 0;
  tab.downPayment = offer.downPayment as number;
  tab.mileage = offer.mileage;
  tab.tradeIn = 0;
};

const selectBaseOfferBuy = (offer: OfferForView, tab: BuyOfferTab) => {
  tab.useCustomRate = true;
  tab.terms = offer.terms as unknown as string;
  tab.rate = offer.rate;
};

export const selectBaseOffer = (
  offer: OfferForView,
  tab: BuyOfferTab | LeaseOfferTab
) => {
  if (offer.offerType === OfferType.LEASE) {
    selectBaseOfferLease(offer, tab as LeaseOfferTab);
  } else {
    selectBaseOfferBuy(offer, tab as BuyOfferTab);
  }
};

export const addToTierTermOffersMap = ({
  tierTermsOffersMap,
  tierTermKey,
  offer,
}: {
  tierTermsOffersMap: TierTermsOffersMap;
  tierTermKey: string;
  offer: OfferForView;
}) => {
  if (!tierTermsOffersMap[tierTermKey]) {
    tierTermsOffersMap[tierTermKey] = [];
  }
  tierTermsOffersMap[tierTermKey].push(offer);
};

export const getMaxTerm = (aprOffers: LeaseAndAprOfferFromService[]) => {
  const terms = aprOffers
    .filter(item => item.isAdvertised)
    .map(item => item.highTerm);
  const na = Array.from(new Set(terms));
  return Math.max(...na, 60);
};

export const getNonAdOffer = (
  tierTermsNonAdMap: TierTermsNonAdMap,
  key: string
) => tierTermsNonAdMap[key];

export const getCreditScoreItem = (tier: string, selectedTab: Tab) => {
  return getCreditScoreConstant(ZipCodeStore.regionCode, selectedTab).find(
    _creditScore => _creditScore.tier === tier
  );
};

export const getOffersByTierAndTerm = ({
  tierTermsOffersMap,
  tier,
  term,
}: {
  tierTermsOffersMap: TierTermsOffersMap;
  tier: string;
  term: string;
}) => tierTermsOffersMap[createTierTermKey(tier, term)] ?? [];

export const getOffersByTierTermKey = (
  tierTermsOffersMap: TierTermsOffersMap,
  tierTermKey: string
) => tierTermsOffersMap[tierTermKey] ?? [];

export const convertTierNumber = (tierNumber: number) =>
  tierNumber === 0 ? '1+' : tierNumber === 8 ? '7+' : tierNumber.toString();

export const isDefaultLeaseTerms = (
  tab: Tab,
  tier: number,
  term: number,
  newInitOfferObj?: InitObj
) => tab === 'lease' && tier === 0 && term === 36 && newInitOfferObj;

export const isDefaultBuyTerms = (
  tab: Tab,
  tier: number,
  term: number,
  newInitOfferObj?: InitObj
) => tab === 'buy' && tier === 0 && term === 60 && newInitOfferObj;
