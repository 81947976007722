import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import i18n from 'i18next';

import {
  Dealer,
  RequestAQuoteRequest,
} from '../../../../models/VIEW/RequestAQuote';
import * as PostMessageService from '../../../../services/PostMessageService';
import * as contactDealerAnalytics from '../../../../utils/Analytics/contactDealerAnalytics';
import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { useStore } from '../../../../stores';
import {
  GradeView,
  GradeVehicleModel,
} from '../../../../models/VIEW/EstimatorStore';

import { createSelectedTrim, sanitizeString } from './utils';
import {
  getDealersForContactForm,
  submitContactDealerForm,
} from '../../../../services/DealersService';

const charRegex = RegExp(/[A-Z]+/i);
const emailRegex = RegExp(
  /^[_A-Z0-9+-]+(\.[_A-Z0-9+-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)*(\.[A-Z]{2,4})$/i
);
const phoneRegex = RegExp(/^(\(\d{3}\)|\d{3}-|\d{3})(\d{3}-|\d{3})\d{4}$/);

type Props = {
  setCurrentZip: (idx: string) => void;
  setSelectedDealer: (idx: Dealer) => void;
  setDealers: (idx: Dealer[]) => void;
  setEditZipCode: (idx: boolean) => void;
  zipRef: React.RefObject<HTMLInputElement>;
  staticCommentTextArea: React.RefObject<HTMLTextAreaElement>;
  currentZip: string;
  limit: number;
  setLimit: (idx: number) => void;
  firstInteraction: boolean;
  setFirstIntefaction: (idx: boolean) => void;
  selectedDealer?: Dealer;
  staticComment: string;
  setIsSubmitted: (idx: boolean) => void;
};

type FormState = {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  preference: string;
  comment: string;
};

const validate = (values: FormState) => {
  const errors = {} as FormState;
  if (!values.firstname || !charRegex.test(values.firstname)) {
    errors.firstname = i18n.t('contact_dealer_modal.first_name_required');
  }
  if (!values.lastname || !charRegex.test(values.lastname)) {
    errors.lastname = i18n.t('contact_dealer_modal.last_name_required');
  }
  if (
    values.preference === 'email' &&
    (!values.email || !emailRegex.test(values.email))
  ) {
    errors.email = i18n.t('contact_dealer_modal.email_required');
  }
  if (
    values.preference === 'phone' &&
    (!values.phone || !phoneRegex.test(values.phone))
  ) {
    errors.phone = i18n.t('contact_dealer_modal.phone_required');
  }
  return errors;
};

export const useContactDealerHandlers = ({
  setCurrentZip,
  setSelectedDealer,
  setDealers,
  setEditZipCode,
  zipRef,
  limit,
  setLimit,
  currentZip,
  staticCommentTextArea,
  firstInteraction,
  setFirstIntefaction,
  selectedDealer,
  staticComment,
  setIsSubmitted,
}: Props) => {
  const { isMobile } = useBreakpoints();
  const { store } = useStore();
  const {
    estimatorStore: {
      selectedYearSeries,
      selectedYearSeries: { tabManager },
      selectedTab,
    },
  } = useStore();

  const [dealersLength, setDealersLength] = useState(0);
  const [firedPageLoadTag, setFiredPageLoadTag] = useState(false);

  const selectedModel = tabManager[selectedTab]
    ?.selectedModel as GradeVehicleModel;
  const {
    title,
    series: { title: titleForRequest },
  } = selectedYearSeries;
  const { title: gradeTitle } = tabManager[selectedTab]
    ?.selectedGrade as GradeView;
  const { selectedOffers } = selectedModel.peOffers[selectedTab];

  const selectedTrim = createSelectedTrim({
    gradeTitle,
    titleForRequest,
    selectedTab,
    year: selectedYearSeries.year.title,
    trim: selectedModel.code,
  });

  /* Handlers to return */
  const handleDealerZipChange = async (inputZipCode: string) => {
    setCurrentZip(inputZipCode);
  };

  const handleEditZipCodeOpen = () => {
    setEditZipCode(true);
    setTimeout(() => {
      if (zipRef && zipRef.current) {
        zipRef.current.focus();
        zipRef.current.select();
      }
    });
  };

  const loadMore = () => {
    setLimit(limit + 5);
    setTimeout(() => {
      PostMessageService.sendHeight();
    });
  };

  const onClose = () => {
    setTimeout(() => {
      PostMessageService.sendHeight();
    });
  };

  const onSubmit = async (values: FormState) => {
    const formData = {
      firstname: sanitizeString(values.firstname),
      lastname: sanitizeString(values.lastname),
      email: sanitizeString(values.email),
      phone: values.phone,
      preference: values.preference,
      inputComment: sanitizeString(values.comment),
      vehicle: title,
      dealer: selectedDealer as Dealer,
      staticComment,
      isMobile,
    } as RequestAQuoteRequest;

    try {
      contactDealerAnalytics.onSubmitClick(selectedOffers);
      await submitContactDealerForm(formData, selectedTrim);
      contactDealerAnalytics.fireKMCADCompleteTag(
        selectedOffers,
        selectedDealer
      );
      setIsSubmitted(true);
      window.scrollTo(0, 0);
    } catch (error) {
      store.serviceError();
    }
  };

  // set up form state
  const formik = useFormik<FormState>({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      preference: 'email',
      comment: '',
    },
    validate,
    onSubmit,
  });

  // analytics
  const handleInteraction = (label: any) => {
    if (!firstInteraction) {
      const searchOpts = {
        zip: currentZip,
        search_count: dealersLength,
      };
      contactDealerAnalytics.fireCADStartTag(
        label,
        selectedOffers,
        selectedDealer,
        searchOpts
      );
      setFirstIntefaction(true);
    }
  };

  /* Effects */
  useEffect(() => {
    (async () => {
      if (currentZip.length === 5) {
        const dealers = await getDealersForContactForm(currentZip);
        setSelectedDealer(dealers[0]);
        setDealers(dealers);
        setDealersLength(dealers.length);
        if (staticCommentTextArea.current) {
          staticCommentTextArea.current.setAttribute(
            'style',
            `height: ${staticCommentTextArea.current.scrollHeight}px`
          );
        }
        setEditZipCode(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentZip, staticCommentTextArea]);

  // on init
  useEffect(() => {
    if (selectedDealer && !firedPageLoadTag) {
      contactDealerAnalytics.firePageLoadTag(selectedOffers, selectedDealer);
      // WFMB-204
      setFiredPageLoadTag(true);
    }
    PostMessageService.sendHeight();
  }, [selectedOffers, selectedDealer, firedPageLoadTag, setFiredPageLoadTag]);

  return {
    handleDealerZipChange,
    handleEditZipCodeOpen,
    handleInteraction,
    loadMore,
    onSubmit,
    formik,
    onClose,
  };
};
