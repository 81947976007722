import React from 'react';
import cx from 'classnames';

import styles from './Button.module.scss';

type Props = {
  color?: 'red' | 'grey' | 'black';
  onClick: React.MouseEventHandler;
  className?: string;
};

const Button: React.FC<Props> = props => {
  const {
    onClick,
    children,
    className,
    color = 'red',
    ...remainingProps
  } = props;

  return (
    <button
      className={cx(
        styles.btn,
        styles.button,
        styles[`button-${color}`],
        className
      )}
      onClick={onClick}
      {...remainingProps}
    >
      {children}
    </button>
  );
};

export default Button;
