import cx from 'classnames';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../stores';

import Slider, { CustomArrowProps } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { OfferForView } from '../../../../stores/EstimatorPage/Factories/offerFactory';

import styles from './Offers.module.scss';
import slickStyles from './slicker.module.scss';
import './slickerOverride.css';

import OfferDetailsModal from '../OfferDetailsModal';
import ContactDealerModal from '../ContactDealer';
import Modal from '../../../../components/Modal';

import Offer from './Offer';

type SlickArrowProps = {
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  prev?: boolean;
} & CustomArrowProps;
const SlickArrow: React.FC<SlickArrowProps> = ({
  onClick,
  prev = false,
  className,
}) => {
  const isDisabled = className?.includes('slick-disabled');
  const cn = cx(prev ? slickStyles.sliderBtnPrev : slickStyles.sliderBtnNext, {
    [slickStyles.disabled]: isDisabled,
  });
  return <button className={cn} onClick={onClick} />;
};

const Offers: React.FC = () => {
  const settings = {
    infinite: false,
    dots: true,
    focusOnSelect: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    prevArrow: <SlickArrow prev />,
    nextArrow: <SlickArrow />,
    className: 'pe-caro',
  };
  const { isMobile } = useBreakpoints();
  const {
    estimatorStore: {
      payOptForm: { offers },
    },
    modalStore: { offerDetailId },
  } = useStore();
  const numberOfOffers = offers?.length ?? 0;

  return (
    <div className={cx(styles.root, { [styles.noOffers]: !numberOfOffers })}>
      {numberOfOffers > 0 && (
        <>
          <div className={styles.seperator} />
          <div className={styles.listWrapper}>
            {!isMobile ? (
              <div className={cx(styles.list, styles.tablet)}>
                <Slider {...settings}>
                  {offers?.map((offer: OfferForView) => (
                    <Offer key={offer.id} offer={offer} />
                  ))}
                </Slider>
              </div>
            ) : (
              <ul className={styles.list}>
                {offers?.map((offer: OfferForView) => (
                  <li key={offer.id}>
                    <Offer offer={offer} />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </>
      )}
      {offers?.map((offer: OfferForView) =>
        offer.id === offerDetailId ? (
          <OfferDetailsModal offer={offer} key={offer.id} />
        ) : null
      )}

      <Modal>
        <ContactDealerModal />
      </Modal>
    </div>
  );
};

export default observer(Offers);
