import i18n from 'i18next';

import { observable, decorate } from 'mobx';
import EstimatorStore from '../../stores/EstimatorPage';
import { history } from '../../stores/index';
import { getQueryParams } from '../../utils/history';
import ZipCodeStore from '../../stores/ZipCodeStore';

import ParentLocationService from '../../services/ParentLocationService';
import * as vehicleSelectorAnalytics from '../../utils/Analytics/vehicleSelectorAnalytics';
import { SeriesForView } from '../../models/VIEW/EstimatorStore';

const GlobalStore = {
  isFetching: true, // should be false but have issue with defaults on estimator page,
  firstPageLoaded: false,
  isStatusBarVisible: false,
  statusBarMsg: '',
  parentLocationUrlSet: false, // required to prevent firing analytics tags too early
  setFirstPageLoaded: () => {
    GlobalStore.firstPageLoaded = true;
  },
  setGlobalParams: () => {
    GlobalStore.setRefFromQueryParams();
    GlobalStore.setLanguageFromQueryParams();
  },
  setRefFromQueryParams: () => {
    const { ref } = getQueryParams();
    if (ref && typeof ref === 'string') {
      ParentLocationService.setUrl(ref);
    }
    GlobalStore.parentLocationUrlSet = true;
  },
  setLanguageFromQueryParams: () => {
    // grabs language from query string and sets it internally if present...
    const { lang } = getQueryParams();
    if (lang && (lang === 'es' || lang === 'en')) {
      i18n.changeLanguage(lang);
    } else if (lang && lang !== 'es' && lang !== 'en') {
      GlobalStore.showStatusBar('Incorrect language parameter detected');
    }
  },
  setIsFetching: (isFetching: boolean) => (GlobalStore.isFetching = isFetching),
  selectVehicle: (series: SeriesForView, baseURI: string) => {
    EstimatorStore.isLoaded = false; // in case we've already been to estimator page. required by Analytics and PostMessageService
    EstimatorStore.isModelCodeJumped = false;
    vehicleSelectorAnalytics.fireLinkBlock(series, baseURI);
    EstimatorStore.loadSeriesYearData({
      series: series.series,
      year: series.year,
      clearTrim: true,
    });
  },
  serviceError: (error?: any) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(error);
    }
    GlobalStore.showStatusBar(i18n.t('service_error'));
    GlobalStore.isFetching = false;
  },
  showStatusBar: (msg = '') => {
    GlobalStore.isStatusBarVisible = true;
    GlobalStore.statusBarMsg = msg;
  },
  closeStatusBar: () => {
    GlobalStore.isStatusBarVisible = false;
    GlobalStore.statusBarMsg = '';
  },
  globalTabInCB: () => {
    // Used by PostMessageService
    // calls focus on element of 2 pages.
    // which even element on whichever page is loaded
    // will receive focus
    // left out call from old code base for
    // vehicleselect page as element did not exist

    // Estimator Page
    const changeVehiclePage = document.getElementById('ChangeVehicleLink');
    if (changeVehiclePage) {
      changeVehiclePage.focus();
    }
    // Zip Code Page
    const zipCodeInputPage = document.getElementById('zipcodeInput');
    if (zipCodeInputPage) {
      zipCodeInputPage.focus();
    }
  },
  zipChangeCB: ({ zip }: { zip: string }) => {
    // Used by PostMessageService
    // if we are on zipcode page, or vehicle select page,
    // we can piggyback off of existing zipCode input submit functionality.
    // otherwise on estimator page, we can set zipcode to url query param
    // state, and rerun bootstrap sequence...
    if (history.location.pathname === '/') {
      ZipCodeStore.zipChangeCB(zip);
    } else if (history.location.pathname === '/vehicleSelect') {
      ZipCodeStore.zipChangeCB(zip);
    } else if (history.location.pathname === '/estimator') {
      EstimatorStore.zipChangeCB(zip);
    }
  },
};

decorate(GlobalStore, {
  isFetching: observable,
  firstPageLoaded: observable,
  isStatusBarVisible: observable,
  parentLocationUrlSet: observable,
  statusBarMsg: observable,
});

export default GlobalStore;
