import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../../stores';

import { BottomSection } from '../Sections';
import PaymentDetails from './Components/PaymentDetails';
import FinanceTerms from '../FinanceTerms';
import PrintThis from './Components/PrintThis';
import Button from '../../../../modules/ZipCode/components/Button';

import styles from './BottomSectionDesktop.module.scss';
import { Tab } from '../../../../models/VIEW/ViewOffersTab';

type Props = {
  selectedTab: Tab;
};

const BottomSectionDesktop: React.FC<Props> = ({ selectedTab }) => {
  const { t } = useTranslation();

  const {
    modalStore: { openContactDealerModal },
  } = useStore();

  return (
    <BottomSection>
      <PaymentDetails />
      <div className={styles.buttonWrapper}>
        <Button
          onClick={() => openContactDealerModal()}
          className={styles.btn}
          aria-haspopup="true"
        >
          {t('contact_dealer')}
        </Button>
      </div>
      <div className={styles.divider} />
      <PrintThis />
      <FinanceTerms className={styles.financeTerms} selectedTab={selectedTab} />
    </BottomSection>
  );
};

export default observer(BottomSectionDesktop);
