import { useEffect } from 'react';
import { useCountUp } from 'react-countup';

export const useAnimateNumber = (value: number) => {
  const { countUp, update } = useCountUp({
    start: value,
    end: value,
    delay: 2,
    duration: 1,
  });

  useEffect(() => {
    update(value);
  }, [value, update]);

  return countUp;
};
