import React from 'react';

import { useTranslation } from 'react-i18next';
import styles from './ChangeVehicle.module.scss';
import { Link } from 'react-router-dom';
import { createLocation } from '../../../../utils/navigation';
import handlerStore from '../../../../stores/EstimatorPage/estimatorPageHandlers';

const ChangeVehicleLink = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.changeVehicle}>
      <Link
        id="ChangeVehicleLink"
        to={createLocation('vehicleSelect')}
        onClick={handlerStore.changeVehicleOnClick}
        className={styles.link}
        tabIndex={0}
      >
        {'< '}
        <span className={styles.text}>{t('change_vehicle')}</span>
      </Link>
    </section>
  );
};

export default ChangeVehicleLink;
