import axios from 'axios';
import qs from 'query-string';

import { OffersResponse, TFSContract } from '../models/PEMS/PEMOffers';
import { MarketingDataResponse } from '../models/PEMS/PEMMarketingData';
import { PEMCalculateResponse } from '../models/PEMS/PEMSCalculate';
import brandService from '../services/BrandService';
import { Tab } from '../models/VIEW/ViewOffersTab';
import { slpData } from 'models/VIEW/RequestAQuote';

/* EFC */
export const getDealers = (zipcode: string) => {
  const brandId = brandService.getBrand('dis');
  const url = `${process.env.REACT_APP_DIS_BASE_URL}${process.env.REACT_APP_DIS_GET_DEALERS}`;
  return axios.get(url, {
    headers: {
      'x-api-key': process.env.REACT_APP_DIS_API_KEY,
    },
    params: {
      brandId,
      zipcode,
      resultsMax: 1,
      searchMode: 'pmaProximityLayered',
      resultsMode: 'full',
      resultsFormat: 'json',
    },
  });
};

/* EFC Microservice */
type GetEFCTrimsV2 = {
  attributes?: string;
  region: string;
  series: string;
  years: string;
};
export const getEFCTrimsV2 = ({
  years,
  series,
  region,
  attributes = 'code,title,description,price,attributes',
}: GetEFCTrimsV2) => {
  const BRAND = brandService.getBrand('efc');
  const url = `${process.env.REACT_APP_EFC_MICROSERVICE_BASE_URL_V2}/${BRAND}/${region}/EN/trims?year=${years}&series=${series}&attributes=${attributes}`;
  return axios.get(url, {
    headers: {
      'x-api-key': process.env.REACT_APP_EFC_MICROSERVICE_API_KEY,
    },
  });
};

type GetEFCSeriesV1 = {
  region: string;
  years?: string;
};
export const getEFCSeriesV1 = ({ region, years }: GetEFCSeriesV1) => {
  const BRAND = brandService.getBrand('efc');
  const queryString = qs.stringify({ year: years });
  const url = `${process.env.REACT_APP_EFC_MICROSERVICE_BASE_URL_V1}/${BRAND}/${region}/EN/series?${queryString}`;
  return axios.get(url, {
    headers: {
      'x-api-key': process.env.REACT_APP_EFC_MICROSERVICE_API_KEY,
    },
  });
};

type GetEFCColorsV2 = {
  years: string;
  region: string;
  attributes?: string;
};
export const getEFCColorsV2 = ({
  years,
  region,
  attributes,
}: GetEFCColorsV2) => {
  const BRAND = brandService.getBrand('efc');
  const queryString = qs.stringify({ year: years, attributes });
  const url = `${process.env.REACT_APP_EFC_MICROSERVICE_BASE_URL_V2}/${BRAND}/${region}/EN/colors?${queryString}`;
  return axios.get(url, {
    headers: {
      'x-api-key': process.env.REACT_APP_EFC_MICROSERVICE_API_KEY,
    },
  });
};

/* PEMS */
type GetOffers = {
  year: string;
  state: string;
  series: string;
  region: string;
  tdaCode: string;
};
export const getOffers = ({
  region,
  state,
  series,
  year,
  tdaCode,
}: GetOffers): Promise<{ data: OffersResponse }> => {
  const BRAND = brandService.getBrand('efc');
  const url = `${process.env.REACT_APP_PEMS_BASE_URL}${process.env.REACT_APP_PEMS_GET_OFFERS}/${region}`;
  return axios.get(url, {
    headers: {
      'x-api-key': process.env.REACT_APP_PEMS_KEY,
    },
    params: {
      clientId: process.env.REACT_APP_PEMS_CLIENT_ID,
      year,
      state,
      series,
      brand: BRAND,
      tdaCode: tdaCode.replace('10', ''),
    },
  });
};

type GetMarketingData = {
  lang: string;
  zipCode: string;
  offerId: string;
};

export const getMarketingData = ({
  zipCode,
  offerId,
  lang,
}: GetMarketingData): Promise<{ data: MarketingDataResponse[] }> => {
  const BRAND = brandService.getBrand('dis');
  const url = `${process.env.REACT_APP_PEMS_BASE_URL}${process.env.REACT_APP_PEMS_GET_MARKETING_DATA}/${zipCode}`;
  return axios.get(url, {
    headers: {
      'x-api-key': process.env.REACT_APP_PEMS_KEY,
    },
    params: {
      clientId: process.env.REACT_APP_PEMS_CLIENT_ID,
      offerId,
      lang,
      brandId: BRAND,
    },
  });
};

export type OfferRequestParam = {
  type: string;
  rate?: number;
  term?: number;
  tier?: string;
  name?: string;
  isBase: boolean;
  amount?: number;
  offerId?: string;
  mileage?: number;
  subCash?: number;
  highTerm?: number;
  offerType: string;
  modelCode?: string;
  advertised: boolean;
  amountDown?: number;
  dealerGross?: number;
  downPayment?: number;
  dueAtSigning?: number;
  targetPayment?: number;
  isRateOffer?: boolean;
  isSubvented?: boolean;
  offerAmount?: number;
  compatibility: string[];
  grossCapCost?: number;
  advertisedTrim?: string; // same as modelCode
  tfsContractList?: TFSContract[];
  offerAmountDown?: number;
  additionalCashId?: number;
  advertisedVinMsrp?: number; // lease offers configured with specific msrp in mind
  acquisitionFeeCapitalized: boolean;
  maxAmountPerMonth?: number;
};

export type PEMSCalculateRequestData = {
  acquisitionFee: number;
  acquisitionFeeCapitalized: boolean;
  baseMsrp: number; // baseMsrp + dest fee
  downPayment: number | null;
  mileage: number | null;
  model: string;
  modelYear: string;
  msrp?: number; // msrp query string if present
  offers: OfferRequestParam[];
  pricingArea: string;
  state: string;
  term: string;
  tier: string;
  tradeIn: number;
  trimCode: string;
  type: Tab;
};
export const getPayments = ({
  regionCode,
  data,
}: {
  regionCode: string;
  data: PEMSCalculateRequestData[];
}): Promise<{ data: PEMCalculateResponse }> => {
  const url = `${process.env.REACT_APP_PEMS_BASE_URL}${process.env.REACT_APP_PEMS_GET_CALCULATIONS}/${regionCode}`;
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_PEMS_KEY,
    },
    params: {
      clientId: process.env.REACT_APP_PEMS_CLIENT_ID,
    },
  });
};

export const getPEQuote = (data: slpData) => {
  const url = `${process.env.REACT_APP_SLP_API}/leads`;
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_SLP_API_KEY}`,
    },
  });
};

export const getDealersContactForm = async (inputZipCode: string) => {
  const brandId = brandService.getBrand('dis');
  const url = `${process.env.REACT_APP_DIS_BASE_URL}${process.env.REACT_APP_DIS_GET_DEALERS}`;

  const options = {
    headers: {
      'x-api-key': process.env.REACT_APP_DIS_API_KEY,
    },
    params: {
      brandId,
      zipcode: inputZipCode,
      resultsMax: 25,
      searchMode: 'pmaProximityLayered',
      resultsMode: 'starOnly',
      proximityMode: 'expandingRadius',
      resultsFormat: 'json',
    },
  };
  const pmaRespA = axios.get(url, options);
  const proximityRespA = axios.get(url, {
    ...options,
    params: {
      ...options.params,
      searchMode: 'proximityOnly',
    },
  });
  const [pmaResp, proximityResp] = await Promise.all([
    pmaRespA,
    proximityRespA,
  ]);
  return [pmaResp, proximityResp];
};

export const getMycoFile = async () => {
  const url = `${process.env.REACT_APP_JSDS_BASE_URL}/BATVD/clsseriesmodels.json`;
  let modelsResp;

  if (
    process.env.REACT_APP_MYCO_USERNAME &&
    process.env.REACT_APP_MYCO_PASSWORD
  ) {
    const options = {
      auth: {
        username: process.env.REACT_APP_MYCO_USERNAME,
        password: process.env.REACT_APP_MYCO_PASSWORD,
      },
    };
    modelsResp = await axios.get(url, options);
  } else {
    modelsResp = await axios.get(url);
  }

  return modelsResp.data;
};
