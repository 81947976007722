import React from 'react';

import styles from './Sections.module.scss';

export const TopSection: React.FC = ({ children }) => (
  <div className={styles.topSection}>
    <div className={styles.topSectionContainer}>{children}</div>
  </div>
);

export const MiddleSection: React.FC = ({ children }) => (
  <div className={styles.middleSection}>
    <div className={styles.middleSectionContainer}>{children}</div>
  </div>
);

export const BottomSection: React.FC = ({ children }) => (
  <div className={styles.bottomSection}>
    <div className={styles.bottomSectionContainer}>
      <div className={styles.details}>{children}</div>
    </div>
  </div>
);
