class ParentLocationService {
  private url = '';
  private hostname = 'www.toyota.com'; // set default hostname to toyota.com; bat always uses ref so if TCOM don't pass one down we'll still return correct getIsToyota bool
  private pathname = '';
  private isInventoryDetailsPage = false;
  private readonly toyotaRegex = /\.toyota\.com/;
  private readonly jsdsLowerEnvRegex = /\.jsds\./;
  private readonly rctRegex = /\.rct\./;

  public setUrl(url: string) {
    this.url = url;
    const locationObject = new URL(url);
    this.hostname = locationObject.hostname;
    this.pathname = locationObject.pathname;
    this.isInventoryDetailsPage = !!this.pathname.match('inventory-detail');
  }

  public getUrl() {
    return this.url;
  }

  public getHostname() {
    return this.hostname;
  }

  public getPathname() {
    return this.pathname;
  }

  public getIsInventoryDetailsPage() {
    return this.isInventoryDetailsPage;
  }

  public getIsToyota() {
    return (
      this.toyotaRegex.test(this.getHostname()) &&
      !this.jsdsLowerEnvRegex.test(this.getHostname())
    );
  }

  public getIsRct() {
    return this.rctRegex.test(document.referrer);
  }
}

const parentLocationService = new ParentLocationService();

export default parentLocationService;
