import React from 'react';
import { useStore } from '../../../../../../stores';
import { observer } from 'mobx-react-lite';

import styles from './PaymentDetails.module.scss';
import Cell from '../../../Cells';

const PaymentDetails: React.FC = () => {
  const { estimatorStore } = useStore();
  const {
    payOptForm: { payment },
  } = estimatorStore;
  const formatedPayment = typeof payment === 'number' ? String(payment) : '-';

  return (
    <>
      <Cell
        className={styles.amount}
        valueClassName={styles.cellValue}
        value={formatedPayment}
        addCurrency
        animated={!isNaN(Number(formatedPayment))}
        subValueClassName={styles.subValueMobile}
        subValue="/ month"
      />
    </>
  );
};

export default observer(PaymentDetails);
