import React from 'react';
import i18n, { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import handlerStore from '../../../../stores/EstimatorPage/estimatorPageHandlers';

import Dropdown from './components/Dropdown';
import Toggle from './components/Toggle';
import Input from './components/Input';
import Cell from './components/Cell';

import {
  MileageConstant,
  MileageConstantSET,
} from '../../../../constants/Mileage';
import {
  CreditScore,
  getCreditScoreConstant,
} from '../../../../constants/CreditScoreConstants';
import { formatNumber } from '../../../../utils/numbers';

import { Tab } from '../../../../models/VIEW/ViewOffersTab';
import { useStore } from '../../../../stores';
import { fireEstimatedTradeInTag } from 'utils/Analytics/estimatorAnalytics';
import EstimatorStore from 'stores/EstimatorPage';
import { GradeVehicleModel } from 'models/VIEW/EstimatorStore';
import styles from './PaymentOptions.module.scss';

// t from useTranslation
const createEstimtedCreditScoreOptions = (
  t: TFunction,
  regionCode: string,
  selectedTab: Tab
) => {
  return getCreditScoreConstant(regionCode, selectedTab).map(
    (creditScore: CreditScore) => ({
      label:
        regionCode === '500'
          ? `${creditScore.score} ${t(creditScore.label)}`
          : `${t(creditScore.label)} ${creditScore.score}`,
      value: creditScore.id,
    })
  );
};

const createMileageOptions = (regionCode: string) => {
  const mileageConstant =
    regionCode === '500' ? MileageConstantSET : MileageConstant;

  return mileageConstant.map(mileage => ({
    label: String(formatNumber(mileage)),
    value: mileage,
  }));
};

const defaultBuyTermOptions = [
  { label: `24 ${i18n.t('months')}`, value: 24 },
  { label: `36 ${i18n.t('months')}`, value: 36 },
  { label: `48 ${i18n.t('months')}`, value: 48 },
  { label: `60 ${i18n.t('months')}`, value: 60 },
  { label: `72 ${i18n.t('months')}`, value: 72 },
];

type Option = { label: string; value: number };

const createTermLengthsOptions = (tab: Tab, availableTerms?: number[]) => {
  if (tab === 'buy') {
    return defaultBuyTermOptions;
    // if tab === 'Lease' available Terms should be defined
  } else if (availableTerms) {
    return availableTerms.map(idx => ({
      label: `${idx} ${i18n.t('months')}`,
      value: idx,
    }));
  }
  return []; // sonarQube
};

const useCreateTermLengthOptions = (tab: Tab, availableTerms?: number[]) => {
  const [termLengthOptions, setOptions] = React.useState(
    createTermLengthsOptions(tab, availableTerms)
  );
  React.useEffect(() => {
    setOptions(createTermLengthsOptions(tab, availableTerms));
  }, [tab, availableTerms]);

  return termLengthOptions;
};

const PaymentOptions: React.FC = () => {
  const { t } = useTranslation();
  const {
    estimatorStore: { payOptForm, selectedTab },
    modalStore: { openKbbTradeInModal },
    zipCodeStore: { regionCode },
  } = useStore();

  const {
    rate,
    terms,
    mileage,
    downPayment,
    tradeIn,
    availableTerms,
    creditScore,
    markup,
  } = payOptForm;
  const isBuyTab = selectedTab === 'buy';
  const termLengthsOptions = useCreateTermLengthOptions(
    selectedTab,
    availableTerms
  ) as Option[];
  const estimtedCreditScoreOptions = createEstimtedCreditScoreOptions(
    t,
    regionCode,
    selectedTab
  );

  const mileageOptions = createMileageOptions(regionCode);

  const toggleOptions = [
    { label: t('buy'), value: 'buy' },
    { label: t('lease'), value: 'lease' },
  ];
  const toggleOnChange = (event: any) => {
    handlerStore.toggleSelectedTab(event.target.value);
  };

  // computed in results from calculations api call
  const isToggleDisabled =
    selectedTab === 'buy' && payOptForm.isLeaseIneligible;

  const selectedCreditScore =
    regionCode === '500' ? creditScore.score : creditScore.label;

  const openKbbModal = () => {
    openKbbTradeInModal();

    const selectedModel = EstimatorStore.selectedYearSeries.tabManager[
      EstimatorStore.selectedTab
    ]?.selectedModel as GradeVehicleModel;
    const offers = selectedModel.peOffers[EstimatorStore.selectedTab].offers;

    fireEstimatedTradeInTag(offers);
  };

  return (
    <section className={styles.root}>
      <div className={styles.row}>
        {/* Toggle */}
        <Toggle
          options={toggleOptions}
          value={selectedTab}
          onChange={toggleOnChange}
          disabled={isToggleDisabled}
        />

        {/* Estimated credit score dropdown */}
        <Cell label={t('Estimated Credit Score')} sup="1">
          <Dropdown
            options={estimtedCreditScoreOptions}
            value={t<string>(selectedCreditScore)}
            onClick={handlerStore.onCreditChange}
          />
        </Cell>

        {/* Cash down / Cash out of pocket input  */}
        <Cell
          label={isBuyTab ? t('cash_down') : t('cash_out_of_pocket')}
          sup={isBuyTab ? '2' : '8'}
        >
          <Input
            value={String(downPayment)}
            onChange={handlerStore.onDownpaymentChange}
            format
          />
        </Cell>

        {/* Term length dropdown */}
        <Cell label={t('term_duration')} sup="3">
          <Dropdown
            options={termLengthsOptions}
            value={`${terms} ${t('months')}`}
            onClick={handlerStore.onTermsChange}
          />
        </Cell>

        {/* APR / Annual mileage dropdown */}
        <Cell
          label={isBuyTab ? t('estimated_apr') : t('annual_mileage')}
          sup={isBuyTab ? '4' : '6'}
        >
          {isBuyTab && (
            <Input
              value={`${formatNumber((rate ?? 0) + (markup ?? 0))}%`}
              disabled
              format={false}
            />
          )}
          {!isBuyTab && (
            <Dropdown
              options={mileageOptions}
              value={formatNumber(mileage as number)}
              onClick={handlerStore.onMileageChange}
            />
          )}
        </Cell>

        {/* Estimated trade in value input */}
        <Cell label={t('trade_in')} sup="5">
          <Input
            value={String(tradeIn)}
            onChange={handlerStore.onTradeInChange}
          />
        </Cell>

        <div className={styles.tradeInCell}>
          <button className={styles.button} onClick={openKbbModal} tabIndex={0}>
            <span>{t('trade_in_cta')}</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default observer(PaymentOptions);
