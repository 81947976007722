export const InactiveSeries = [
  'priusplugin',
  'mirai',
  'priusv',
  'yarisliftback',
  'corollaim',
  'yarisia',
  'priusc',
];

type SuppressedTrimsType = {
  [year: string]: string[];
};
export const SuppressedTrims: SuppressedTrimsType = {
  '2020': ['4522', '4526', '4530', '4534'], // RAV4 Hybrid
};

export const displayCategories: {
  [id: string]: Array<{ code: string; name: string; order?: string[] }>;
} = {
  toyota: [
    {
      code: 'carsminivans',
      name: 'Cars & Minivan',
      order: [
        'yaris',
        'yarishatchback',
        'corolla',
        'corollahybrid',
        'corollahatchback',
        'prius',
        'camry',
        'camryhybrid',
        'priusprime',
        'avalonhybrid',
        '86',
        'supra',
        'sienna',
        'toyotacrown',
        'grcorolla',
      ],
    },
    {
      code: 'trucks',
      name: 'Trucks',
      order: ['tacoma', 'tacomahybrid', 'tundra', 'tundrahybrid'],
    },
    {
      code: 'crossoverssuvs',
      name: 'Crossovers & SUVs',
      order: [
        'corollacross',
        'rav4',
        'rav4hybrid',
        'venza',
        'rav4prime',
        'grandhighlander',
        'grandhighlanderhybrid',
        'highlander',
        'highlanderhybrid',
        '4runner',
        'sequoia',
        'landcruiser',
        'corollacrosshybrid',
        'toyotacrownsignia',
      ],
    },
    {
      code: 'hybrids',
      name: 'Hybrids & FCV',
      order: [
        'corollahybrid',
        'prius',
        'priusprime',
        'rav4hybrid',
        'rav4prime',
        'camryhybrid',
        'avalonhybrid',
        'grandhighlanderhybrid',
        'highlanderhybrid',
        'tacomahybrid',
        'tundrahybrid',
        'bz4x',
        'toyotacrown',
        'corollacrosshybrid',
      ],
    },
  ],
  lexus: [
    { code: 'sedans', name: 'Sedans' },
    { code: 'suvs', name: 'SUVs' },
    { code: 'coupes', name: 'Coupes' },
    { code: 'fperf', name: 'F Performance' },
  ],
};

type VehicleMap = {
  [id: string]: {
    [id: string]: string;
  };
};

export const VehicleCatMap: VehicleMap = {
  toyota: {
    '4runner': 'crossoverssuvs',
    avalonhybrid: 'hybrids',
    camry: 'carsminivans',
    camryhybrid: 'hybrids',
    corolla: 'carsminivans',
    corollacross: 'crossoverssuvs',
    corollahybrid: 'hybrids',
    grandhighlander: 'crossoverssuvs',
    grandhighlanderhybrid: 'hybrids',
    highlander: 'crossoverssuvs',
    highlanderhybrid: 'hybrids',
    landcruiser: 'crossoverssuvs',
    mirai: 'hybrids',
    priusv: 'hybrids',
    rav4: 'crossoverssuvs',
    rav4hybrid: 'hybrids',
    venza: 'crossoverssuvs',
    rav4prime: 'crossoverssuvs',
    sequoia: 'crossoverssuvs',
    sienna: 'carsminivans',
    supra: 'carsminivans',
    tacoma: 'trucks',
    tundra: 'trucks',
    tundrahybrid: 'trucks',
    tacomahybrid: 'trucks',
    yaris: 'carsminivans',
    yarisliftback: 'carsminivans',
    yarishatchback: 'carsminivans',
    priusc: 'hybrids',
    prius: 'hybrids',
    '86': 'carsminivans',
    corollaim: 'carsminivans',
    yarisia: 'carsminivans',
    priusprime: 'hybrids',
    corollahatchback: 'carsminivans',
    bz4x: 'hybrids',
    toyotacrown: 'hybrids',
    toyotacrownsignia: 'crossoverssuvs',
    grcorolla: 'carsminivans',
    corollacrosshybrid: 'hybrids',
  },
  lexus: {
    IS: 'sedans',
    ISC: 'sedans',
    ISF: 'fperf',
    ES: 'sedans',
    ESh: 'hybrids',
    GS: 'sedans',
    GSh: 'hybrids',
    GSF: 'fperf',
    LS: 'sedans',
    NX: 'suvs',
    NXh: 'hybrids',
    RX: 'suvs',
    RXh: 'hybrids',
    GX: 'suvs',
    LX: 'suvs',
    RC: 'coupes',
    RCF: 'fperf',
    CTh: 'hybrids',
  },
};

export type ModelMap = {
  angle?: number;
  category?: number;
  vehicleColor?: string;
  vehicleColorYear?: string;
  vehicleSelectModel?: number;
};
export const EFCModelMap: { [id: string]: ModelMap } = {
  // toyota
  '4runner': {
    vehicleColor: '03R3',
    angle: 1,
  },
  '86': {
    vehicleColor: '0K1X',
  },
  avalonhybrid: {
    vehicleColor: '03U5',
  },
  camry: {
    vehicleColor: '03T3',
  },
  camryhybrid: {
    vehicleColor: '01J9',
  },
  tundrahybrid: {
    vehicleColor: '0089',
  },
  corolla: {
    vehicleColor: '01K3',
    vehicleSelectModel: 1864,
  },
  corollacross: {
    vehicleColor: '01K3',
  },
  corollacrosshybrid: {
    vehicleColor: '0D18',
  },
  corollahybrid: {
    vehicleColor: '0040',
  },
  corollaim: {
    vehicleColor: '01F7',
  },
  corollahatchback: {
    vehicleColor: '08W9',
  },
  grandhighlander: {
    vehicleColor: '01L6',
  },
  grandhighlanderhybrid: {
    vehicleColor: '01L6',
  },
  highlander: {
    vehicleColor: '01G3',
    category: 2,
  },
  highlanderhybrid: {
    vehicleColor: '03T3',
  },
  landcruiser: {
    vehicleColor: '01G3',
    angle: 1,
  },
  priusc: {
    vehicleColor: '0791',
  },
  prius: {
    vehicleColor: '0089',
  },
  priusv: {
    vehicleColor: '04W4',
  },
  priusprime: {
    vehicleColor: '03U5',
  },
  rav4: {
    vehicleColor: '01G3',
  },
  rav4hybrid: {
    vehicleColor: '02QJ',
  },
  rav4prime: {
    vehicleColor: '02SC',
  },
  sequoia: {
    vehicleColor: '08X8',
  },
  sienna: {
    vehicleColor: '01J9',
  },
  supra: {
    vehicleColor: '0D06',
  },
  tacoma: {
    vehicleColor: '01G3',
    angle: 1,
  },
  tundra: {
    vehicleColor: '03U5',
  },
  venza: {
    vehicleColor: '01K9',
  },
  yaris: {
    vehicleColor: '045P',
  },
  yarisia: {
    vehicleColor: '042A',
  },
  yarisliftback: {
    vehicleColor: '08X2',
  },
  yarishatchback: {
    vehicleColor: '044J',
  },
  bz4x: {
    vehicleColor: '01L5',
  },
  toyotacrown: {
    vehicleColor: '02YX',
  },
  toyotacrownsignia: {
    vehicleColor: '01L6',
  },
  grcorolla: {
    vehicleColor: '02YX',
  },
  tacomahybrid: {
    vehicleColor: '0070',
  },

  // lexus
  IS: {},
  ISC: {},
  ISF: {},
  ES: {},
  ESh: {},
  GS: {},
  GSh: {},
  GSF: {},
  LS: {},
  NX: {},
  NXh: {},
  RX: {},
  RXh: {},
  GX: {},
  LX: {},
  RC: {},
  RCF: {},
  CTh: {},
};
