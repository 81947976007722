import { Tab } from 'models/VIEW/ViewOffersTab';

export type CreditScoreConst = {
  id: number;
  label: string;
  labelBuySET: string;
  labelLeaseSET: string;
  score: string;
  scoreBuySET: string;
  scoreLeaseSET: string;
  tier: string;
};

export type CreditScore = {
  id: number;
  label: string;
  score: string;
  tier: string;
};

export const CreditScoreConstant: CreditScoreConst[] = [
  {
    id: 0,
    label: 'credit_score_text.excellent',
    labelBuySET: 'credit_score_text.excellent',
    labelLeaseSET: '',
    score: '720+',
    scoreBuySET: '780+',
    scoreLeaseSET: '740+',
    tier: '1+',
  },
  {
    id: 1,
    label: 'credit_score_text.great',
    labelBuySET: '',
    labelLeaseSET: '',
    score: '719-690',
    scoreBuySET: '740-779',
    scoreLeaseSET: '720-739',
    tier: '1',
  },
  {
    id: 2,
    label: 'credit_score_text.very_good',
    labelBuySET: '',
    labelLeaseSET: '',
    score: '689-670',
    scoreBuySET: '720-739',
    scoreLeaseSET: '700-719',
    tier: '2',
  },
  {
    id: 3,
    label: 'credit_score_text.good',
    labelBuySET: '',
    labelLeaseSET: '',
    score: '669-650',
    scoreBuySET: '700-719',
    scoreLeaseSET: '680-699',
    tier: '3',
  },
  {
    id: 4,
    label: 'credit_score_text.fair',
    labelBuySET: '',
    labelLeaseSET: '',
    score: '649-630',
    scoreBuySET: '680-699',
    scoreLeaseSET: '660-679',
    tier: '4',
  },
  {
    id: 5,
    label: 'credit_score_text.poor',
    labelBuySET: '',
    labelLeaseSET: '',
    score: '629-610',
    scoreBuySET: '660-679',
    scoreLeaseSET: '640-659',
    tier: '5',
  },
  {
    id: 6,
    label: 'credit_score_text.very_poor',
    labelBuySET: '',
    labelLeaseSET: '',
    score: '609-580',
    scoreBuySET: '640-659',
    scoreLeaseSET: '620-639',
    tier: '6',
  },
  {
    id: 7,
    label: 'credit_score_text.extremely_poor',
    labelBuySET: '',
    labelLeaseSET: '',
    scoreBuySET: '620-639',
    scoreLeaseSET: '',
    score: '579-520',
    tier: '7',
  },
  {
    id: 8,
    label: '',
    labelBuySET: '',
    labelLeaseSET: '',
    scoreBuySET: '600-619',
    scoreLeaseSET: '',
    score: '',
    tier: '8',
  },
  {
    id: 9,
    label: '',
    labelBuySET: '',
    labelLeaseSET: '',
    scoreBuySET: '580-599',
    scoreLeaseSET: '',
    score: '',
    tier: '9',
  },
  {
    id: 10,
    label: '',
    labelBuySET: 'Poor',
    labelLeaseSET: '',
    scoreBuySET: '<580',
    scoreLeaseSET: '',
    score: '',
    tier: '10',
  },
];

export const getCreditScoreConstant = (region: string, tab: Tab) => {
  const filteredCreditScoreList = CreditScoreConstant.filter(creditScore => {
    if (
      (region !== '500' && creditScore.score !== '') ||
      (region === '500' && tab === 'buy' && creditScore.scoreBuySET !== '') ||
      (region === '500' && tab === 'lease' && creditScore.scoreLeaseSET !== '')
    ) {
      return creditScore;
    }
    return null;
  });

  return filteredCreditScoreList.map(creditScore => ({
    id: creditScore.id,
    label:
      region === '500'
        ? tab === 'buy'
          ? creditScore.labelBuySET
          : creditScore.labelLeaseSET
        : creditScore.label,
    score:
      region === '500'
        ? tab === 'buy'
          ? creditScore.scoreBuySET
          : creditScore.scoreLeaseSET
        : creditScore.score,
    tier: creditScore.tier,
  }));
};

export const getCreditScoreItemById = (id: number, region: string, tab: Tab) =>
  getCreditScoreConstant(region, tab).find(element => element.id === id);
